import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormCheckbox} from '../../components/common/GroupedCheckbox/types';
import {isObject} from '@mui/x-data-grid/internals';
import {SelectItem} from '../../components/common/Select/types';

export type FormDataType =
  | string
  | number
  | boolean
  | undefined
  | unknown[]
  | object
  | FormCheckbox
  | FormCheckbox[];

export type CustomValidation = {
  isValid: (
    value: FormDataType,
    compareValue?: FormDataType,
    compareValue2?: FormDataType,
  ) => boolean;
  message: string;
};
export interface Validation {
  required?: {
    value: boolean;
    message?: string;
  };
  custom?: CustomValidation[];
}

export interface FormData {
  [Key: string]: FormDataType;
}

export interface Errors {
  [Key: string]: string;
}

const useForm = <T extends FormData>(
  initialState: T,
  initialValidations?: Record<string, Validation>,
  resetCustomErrors?: () => void,
) => {
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState<Errors>({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [validations, setValidations] = useState(initialValidations);
  const {t} = useTranslation();

  useEffect(() => {
    setIsValidForm(checkIsFormValid(initialState));
  }, []);

  const checkIsFormValid = useCallback(
    (data: T) => {
      const isNoErrors = Object.keys(formErrors).length === 0;
      if (data && validations) {
        return Object.keys(validations).every(key => {
          const {required} = validations[key];
          const nonObjectsRequired =
            (required?.value === true && data[key] && isNoErrors) ||
            (required?.value === false && isNoErrors) ||
            (!required && isNoErrors);
          const objectRequired =
            (required?.value === true &&
              isObject(data[key]) &&
              data[key]['value'] != '' &&
              isNoErrors) ||
            !isObject(data[key]) ||
            Array.isArray(data[key]);
          return nonObjectsRequired && objectRequired;
        });
      }
      return isNoErrors ? true : false;
    },
    [validations, formErrors],
  );

  const handleCustomValidation = useCallback(
    (
      custom: CustomValidation[],
      newErrors: Errors,
      compareValue: string | undefined,
      compareValue2: string | undefined,
      value: FormDataType,
      name: string,
    ) => {
      custom.every(c => {
        compareValue && delete newErrors[compareValue];
        compareValue2 && delete newErrors[compareValue2];
        if (
          ((compareValue || compareValue2) &&
            c.isValid &&
            !c.isValid(
              value,
              formData[compareValue],
              formData[compareValue2],
            )) ||
          (!compareValue && !compareValue2 && c.isValid && !c.isValid(value))
        ) {
          newErrors = {...newErrors, [name]: c.message};
          return false;
        } else {
          delete newErrors[name];
          return true;
        }
      });
      return newErrors;
    },
    [formData],
  );

  useEffect(() => {
    setIsValidForm(checkIsFormValid(formData));
  }, [formData, validations]);

  const validate = useCallback(
    (
      name: string,
      value: FormDataType,
      compareValue?: string,
      compareValue2?: string,
    ) => {
      let newErrors = {...formErrors};
      if (validations && validations[name]) {
        const {required, custom} = validations[name];
        if (value === typeof 'string') {
          value = value.trim();
        }

        if (required && required.value && !value) {
          //eslint-disable-next-line
          const message = required.hasOwnProperty('message')
            ? (required.message as string)
            : t('fieldRequired');
          newErrors = {...newErrors, [name]: message};
        } else if (custom && custom.length > 0) {
          newErrors = handleCustomValidation(
            custom,
            newErrors,
            compareValue,
            compareValue2,
            value,
            name,
          );
        } else {
          delete newErrors[name];
        }
      }
      setFormErrors(newErrors);
    },
    [validations, formErrors, handleCustomValidation],
  );

  const handleErrors = useCallback(
    (
      name: string,
      value: FormDataType,
      compareValue?: string,
      compareValue2?: string,
    ) => {
      if (Object.keys(formData) && validations) {
        validate(name, value, compareValue, compareValue2);
      }

      if (resetCustomErrors) {
        resetCustomErrors();
      }
    },
    [formData, resetCustomErrors, validate, validations],
  );

  const handleInputChange = (
    name: string,
    value: string,
    compareValue?: string,
  ) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    handleErrors(name, value, compareValue);
  };

  const handleTextAreaChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    handleErrors(name, value);
  };

  const handleSelectChange = (name: string, value: string | SelectItem) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    let validateValue: string;

    if (typeof value === 'string') {
      validateValue = value;
    } else {
      validateValue = !value ? '' : value.value;
    }
    handleErrors(name, validateValue);
  };

  const handleDatePickerChange = (
    name: string,
    value: string | null,
    compareValue?: string | null,
    compareValue2?: string | null,
  ) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    handleErrors(name, value, compareValue, compareValue2);
  };
  const handleCheckboxChange = (name: string, value: boolean) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    handleErrors(name, value);
  };

  const handleGroupedCheckboxes = (
    name: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    (formData[name] as FormCheckbox[]).map(c => {
      if (c.value === event.target.value) {
        c.checked = event.target.checked;
      }
      return c;
    });
    setFormData({
      ...formData,
      [name]: formData[name],
    });
    handleErrors(name, formData[name]);
  };

  const resetFormData = () => {
    setFormData(initialState);
    setFormErrors({});
    if (resetCustomErrors) {
      resetCustomErrors();
    }
  };

  return {
    formData,
    formErrors,
    isValidForm,
    validations,
    setFormData,
    setValidations,
    handleInputChange,
    handleTextAreaChange,
    handleDatePickerChange,
    handleCheckboxChange,
    handleGroupedCheckboxes,
    handleSelectChange,
    resetFormData,
    handleErrors,
  };
};

export default useForm;
