import React from 'react';
import {SelectItem} from './types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import {Autocomplete} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useTheme} from '@mui/material';

interface Props {
  name: string;
  id: string;
  value?: SelectItem | string | null;
  label?: string;
  errorText?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (name: string, value: string | SelectItem | null) => void;
  items: SelectItem[];
}

export const Index: React.FC<Props> = ({
  id,
  name,
  label,
  items,
  errorText,
  disabled = false,
  placeholder = '',
  value,
  onChange,
}) => {
  const theme = useTheme();

  const selectedValue = React.useMemo(() => {
    let returnedValue: string | null | SelectItem;
    if (value && value != '') {
      if (typeof value != 'string') {
        const findedValue = items?.find(v => v.value === value.value);
        if (findedValue) {
          returnedValue = findedValue;
        } else {
          returnedValue = null;
        }
      } else {
        returnedValue = value;
      }
    } else {
      returnedValue = null;
    }
    return returnedValue;
  }, [items, value]);

  return (
    <Box py={2} px={2}>
      <Typography sx={typographyCss}>{label}</Typography>
      <FormControl error={errorText ? true : false} sx={formcontrolCss}>
        <Autocomplete
          id={id}
          options={items || []}
          value={selectedValue}
          autoHighlight
          sx={selectCss}
          disabled={disabled}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: string | SelectItem | null,
          ) => onChange(name, value)}
          renderInput={params => (
            <TextField
              {...params}
              label={placeholder}
              InputProps={{
                ...params.InputProps,
                sx: selectCss,
                type: 'search',
              }}
            />
          )}
        />
        {errorText && (
          <Typography sx={[errorTextCss, {color: theme.palette.error.light}]}>
            {errorText}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};
const typographyCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  color: 'gray',
  paddingLeft: '5px',
};

const selectCss = {
  display: 'flex',
  flexGrow: 1,
  borderRadius: '15px',
};

const errorTextCss = {
  padding: '7px',
};

const formcontrolCss = {width: '100%'};

export default Index;
