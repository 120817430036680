import React, {useEffect, memo} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useAuthContext} from '../contexts/useAuthContext/useAuthContext';
import {AppBar} from '../components';
import {NavigationItems, Routes} from '../navigation';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

const drawerWidth = 250;

const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isMediumDevice',
})<{
  open?: boolean;
  isMediumDevice?: boolean;
}>(({theme, open, isMediumDevice}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  ...(!isMediumDevice && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  marginLeft: !isMediumDevice ? `-${drawerWidth}px` : 0,
  ...(open &&
    !isMediumDevice && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Layout = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const {user, loadUser} = useAuthContext();
  const mediumDevices = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    loadUser();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRadius: '20px',
          height: '95vh',
          marginLeft: '20px',
          marginTop: '20px',
          transform: 'scale(1)',
          boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}>
      <DrawerHeader>
        <Grid
          container
          display={'flex'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Grid item>
            <Typography
              sx={{
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}>
              Techno Win Machine
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </DrawerHeader>
      <NavigationItems />
    </Drawer>
  );

  const itemsList = () => (
    <Box
      sx={{
        width: 'auto',
        backgroundColor: theme.palette.primary.dark,
        height: '100%',
      }}
      role="drawer"
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}>
      <Typography
        sx={{
          textAlign: 'center',
          pt: 4,
          color: '#fff',
          fontSize: 20,
        }}>
        Techno Win Machine
      </Typography>
      <NavigationItems textColor={'#fff'} isIconWhite={true} />
    </Box>
  );

  const swipeableDrawer = (
    <div>
      <center>
        <>
          <SwipeableDrawer
            anchor={'top'}
            open={open}
            onOpen={handleDrawerOpen}
            onClose={handleDrawerClose}>
            {itemsList()}
          </SwipeableDrawer>
        </>
      </center>
    </div>
  );

  return (
    <Box sx={{display: 'flex'}}>
      {user ? (
        <>
          <AppBar
            open={open}
            handleOpenSideBar={handleDrawerOpen}
            isMediumDevice={mediumDevices}
          />
          {mediumDevices ? swipeableDrawer : drawer}
          <Main open={open} isMediumDevice={mediumDevices}>
            <DrawerHeader />
            <Routes />
          </Main>
        </>
      ) : (
        <Routes />
      )}
    </Box>
  );
};

export default memo(Layout);
