import React from 'react';
import Box from '@mui/system/Box';
import {Input, InputType, Dialog} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {ChangePassword} from '../../../service/PasswordService/types';
import {
  isValidLength,
  isStrongEnough,
} from '../../../validation/appValidations';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (formData: ChangePassword) => void;
}

const Index: React.FC<Props> = ({open, handleSubmit, handleClose}) => {
  const {t} = useTranslation();

  const initData = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const customPasswordValidation = [
    {
      isValid: (value: string) => isValidLength(value, 8),
      message: t('passLengthValidation'),
    },
    {
      isValid: (value: string) => isStrongEnough(value),
      message: t('passStrengthValidation'),
    },
  ];

  const validations = {
    currentPassword: {
      required: {value: true},
      custom: customPasswordValidation,
    },
    newPassword: {
      required: {value: true},
      custom: customPasswordValidation,
    },
    confirmPassword: {
      required: {value: true},
      custom: [
        ...customPasswordValidation,
        {
          isValid: (value: string, compareValue: string) =>
            value === compareValue,
          message: t('changePasswordIsSameValidation'),
        },
      ],
    },
  };

  const {formData, handleInputChange, formErrors, isValidForm} =
    useForm<ChangePassword>(initData, validations);

  return (
    <Dialog
      open={open}
      title={t('changePasswordTitle')}
      fullWidth={true}
      secondButtonDisabled={!isValidForm}
      handleClose={handleClose}
      secondButtonTitle={t('changePasswordModalbtn')}
      handleSecondButtonClick={() => handleSubmit(formData)}>
      <Box>
        <Input
          id="input-currentPassword"
          name="currentPassword"
          label={t('changePasswordCurrent')}
          initialValue={formData.currentPassword}
          onChange={handleInputChange}
          type={InputType.PASSWORD}
          errorText={formErrors?.currentPassword || ''}
        />
        <Input
          id="input-newPassword"
          name="newPassword"
          label={t('changePasswordNew')}
          initialValue={formData.newPassword}
          onChange={handleInputChange}
          type={InputType.PASSWORD}
          errorText={formErrors?.newPassword || ''}
        />
        <Input
          id="input-confirmPassword"
          name="confirmPassword"
          compareValue="newPassword"
          label={t('changePasswordConfirm')}
          initialValue={formData.confirmPassword}
          onChange={handleInputChange}
          type={InputType.PASSWORD}
          errorText={formErrors?.confirmPassword || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
