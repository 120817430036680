import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog, TextArea, PhoneInput} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {User} from '../../../service/UserService/types';
import {useTranslation} from 'react-i18next';
import {
  useGetUserById,
  useUpdateUserById,
} from '../../../service/UserService/useUserService';

interface Props {
  title: string;
  open: boolean;
  handleClose: (refetch: boolean) => void;
}

const Index: React.FC<Props> = ({title, open, handleClose}) => {
  const {t} = useTranslation();
  const {data, isLoading: isPendingData} = useGetUserById();

  const initData = {
    id: null,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    position: '',
    phone: '',
    description: '',
  };

  const validations = {
    description: {
      custom: [
        {
          isValid: (description: string) => description.length <= 500,
          message: t('descriptionLengthValidation'),
        },
      ],
    },
    phone: {
      custom: [
        {
          isValid: (phone: string) => {
            const withoutSpaces = phone.split(' ').join(''); // trim not working
            return withoutSpaces.length <= 15;
          },
          message: t('phoneLengthValidation'),
        },
      ],
    },
  };

  const {
    formData,
    formErrors,
    isValidForm,
    setFormData,
    handleInputChange,
    handleTextAreaChange,
  } = useForm<User>(initData, validations);
  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const {
    isPending: isPendingSubmit,
    updateUserById,
    isSuccess,
  } = useUpdateUserById(formData);

  useEffect(() => {
    if (isSuccess) {
      handleClose(true);
    }
  }, [isSuccess]);

  return (
    <Dialog
      open={open}
      title={title}
      secondButtonDisabled={!isValidForm || isPendingData || isPendingSubmit}
      handleClose={() => handleClose(false)}
      fullWidth={true}
      fullWidthSx={'md'}
      handleSecondButtonClick={updateUserById}>
      <Box>
        <PhoneInput
          name="phone"
          id="edit-profile-phone"
          label={t('editProfilePhone')}
          initialValue={formData.phone}
          onChange={handleInputChange}
          errorText={formErrors?.phone || ''}
        />

        <Input
          id="edit-profile-position"
          name="position"
          label={t('editProfilePosition')}
          initialValue={formData?.position || ''}
          onChange={handleInputChange}
        />
        <TextArea
          id="edit-profile-description"
          name="description"
          label={t('editProfileDescription')}
          initialValue={formData?.description || ''}
          onChange={handleTextAreaChange}
          errorText={formErrors?.description || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
