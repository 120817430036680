import React, {useState, useEffect} from 'react';
import {
  Suspense,
  WorkOrderItemTable,
  WorkOrderItemModal,
  Button,
  WorkOrderModal,
} from '../../components';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useParams} from 'react-router-dom';
import {
  useGetWorkOrder,
  useUpdateWorkOrder,
} from '../../service/WorkOrderService/useWorkOrderService';
import {getFormatedDate} from '../../utils/dateUtils';
import {Theme, useTheme} from '@mui/material';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import {WorkOrderItem} from '../../service/WorkOrderItemsService/types';
import AddIcon from '@mui/icons-material/Add';
import {useCreateWorkOrderItem} from '../../service/WorkOrderItemsService/useWorkOrderItemsService';
import {User} from '../../service/UserService/types';
import {Client} from '../../service/ClientService/types';
import {Device} from '../../service/DevicesService/types';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {Status} from '../../service/types';
import {WorkOrder} from '../../service/WorkOrderService/types';

const Index: React.FC = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const theme = useTheme();
  const {isAdmin} = useAuthContext();

  const [openWOItemModal, setOpenWOItemModal] = useState(false);
  const [openWOModal, setOpenWOModal] = useState(false);

  const {data, isLoading: isPendingData, getWorkOrder} = useGetWorkOrder(id);
  const {isSuccessCreate, createWorkOrderItem} = useCreateWorkOrderItem(
    data?.id,
  );
  const {updateStatus, updateWorkOrder} = useUpdateWorkOrder(data?.id);

  const WOItems = data?.workOrderItems?.map(
    (item: WorkOrderItem, i: number) => {
      return {
        rbr: `${i + 1}.`,
        ...item,
        // price: formatCurrency(item.price), TODO if they decide to show price and amount then uncomment
        // amount: formatCurrency(item.amount),
      };
    },
  );

  useEffect(() => {
    if (isSuccessCreate) {
      setOpenWOItemModal(false);
      getWorkOrder();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (updateStatus === Status.SUCCESS) {
      setOpenWOModal(false);
      getWorkOrder();
    }
  }, [updateStatus]);

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid
        item
        sx={{
          alignSelf: 'center',
        }}>
        <Typography variant="h1">{t('WOTitle')}</Typography>
      </Grid>
      <Grid
        item
        display={'flex'}
        sx={mainGridCss}
        component={Paper}
        elevation={6}>
        <Grid container display={'flex'} direction={'column'}>
          <Suspense isLoading={isPendingData}>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h3" sx={sectionCss}>
                    {t('WOTitleMainInfo')}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="edit WO"
                    component="button"
                    sx={editButtonCss(theme)}
                    onClick={() => setOpenWOModal(true)}
                    disabled={data?.closedDate ? true : false}>
                    <EditOutlinedIcon
                      sx={editButtonIconCss(data?.closedDate ? true : false)}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container direction={'row'} sx={mainInfoContainerCss}>
                <Grid item sx={mainInfoItemCss}>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelCss(theme)}>
                      {t('WONumber')}
                    </Typography>
                    <Typography variant="h5">
                      {data?.workOrderNumber}
                    </Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelCss(theme)}>
                      {t('WOOpenedDate')}
                    </Typography>
                    <Typography variant="h5">
                      {getFormatedDate(data?.openedDate)}
                    </Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelCss(theme)}>
                      {t('WOClosedDate')}
                    </Typography>
                    <Typography variant="h5">
                      {getFormatedDate(data?.closedDate)}
                    </Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelCss(theme)}>
                      {t('WODeviceTakenOver')}
                    </Typography>
                    <Typography variant="h5">
                      {getFormatedDate(data?.deviceTakenOver)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={mainInfoItemCss}>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelSecondGridCss(theme)}>
                      {t('WOWarranty')}
                    </Typography>
                    <Typography variant="h5">
                      {data?.warranty ? t('yes') : t('no')}
                    </Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelSecondGridCss(theme)}>
                      {t('WOWorkDone')}
                    </Typography>
                    <Typography variant="h5">
                      {data?.workDone ? t('yes') : t('no')}
                    </Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelSecondGridCss(theme)}>
                      {t('WOQualityChecked')}
                    </Typography>
                    <Typography variant="h5">
                      {data?.qualityChecked ? t('yes') : t('no')}
                    </Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    <Typography variant="h4" sx={labelSecondGridCss(theme)}>
                      {t('WOSparePartsSaved')}
                    </Typography>
                    <Typography variant="h5">
                      {data?.sparePartsSaved ? t('yes') : t('no')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction={'column'}>
                  {isAdmin && (
                    <Grid item sx={paddingCss}>
                      <Grid container direction={'row'}>
                        <Typography variant="h4" sx={labelCss(theme)}>
                          {t('WOAssignedTo')}
                        </Typography>
                        <Typography variant="h5" sx={assignToCss}>
                          {`${(data?.user as User)?.firstName} ${(data?.user as User)?.lastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item sx={paddingCss}>
                    <Grid container direction={'row'}>
                      <Typography variant="h4" sx={labelCss(theme)}>
                        {t('WOClientName')}
                      </Typography>
                      <Typography variant="h5">
                        {(data?.client as Client)?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction={'row'}>
                      <Typography variant="h4" sx={labelCss(theme)}>
                        {t('WODeviceName')}
                      </Typography>
                      <Typography variant="h5">
                        {(data?.device as Device)?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={paddingCss}>
                    <Grid container direction={'row'}>
                      <Typography variant="h4" sx={labelCss(theme)}>
                        {t('WOFaultDescription')}
                      </Typography>
                      <Typography variant="h5">
                        {data?.faultDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction={'row'}>
                      <Typography variant="h4" sx={labelCss(theme)}>
                        {t('WOComment')}
                      </Typography>
                      <Typography variant="h5">{data?.comment}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display={'flex'} direction={'column'}>
                <Grid container sx={otherInfoTextGridCss}>
                  <Grid item>
                    <Typography variant="h3" sx={sectionCss}></Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={fullWIdthCss}>
              <Grid container sx={WOItemsCss}>
                <Grid>
                  <Typography variant="h3" sx={sectionCss}>
                    {t('WOTitleItems')}
                  </Typography>
                </Grid>
                {!data?.closedDate && (
                  <Grid item alignSelf={'flex-end'}>
                    <Button
                      id="add-WOItem"
                      startIcon={<AddIcon />}
                      text={t('addWOItemBtn')}
                      onClick={() => setOpenWOItemModal(true)}
                    />
                  </Grid>
                )}
              </Grid>
              {data?.workOrderItems && (
                <WorkOrderItemTable
                  data={WOItems}
                  getWO={getWorkOrder}
                  areActionButtonsDisabled={!data?.closedDate ? false : true}
                />
              )}
            </Grid>
            {openWOItemModal && (
              <WorkOrderItemModal
                id="work-order-item-modal"
                title={t('workOrderItemModalCreateTitle')}
                open={openWOItemModal}
                handleClose={() => {
                  setOpenWOItemModal(false);
                }}
                handleSubmit={(formData: WorkOrderItem) =>
                  createWorkOrderItem([formData])
                }
              />
            )}
            {openWOModal && (
              <WorkOrderModal
                id="work-order-modal"
                title={t('WOEditModalTitle')}
                data={data}
                open={openWOModal}
                handleClose={() => {
                  setOpenWOModal(false);
                }}
                handleSubmit={(formData: WorkOrder) =>
                  updateWorkOrder(formData)
                }
              />
            )}
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};

const editButtonCss = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  marginLeft: '10px',
  marginTop: '5px',
  width: 50,
  height: 50,
  borderRadius: '10px',
});

const editButtonIconCss = (disabled: boolean) => ({
  color: disabled ? '#808080' : '#000',
  width: 40,
  height: 40,
});

const fullWIdthCss = {
  width: '100%',
};

const WOItemsCss = {
  display: 'flex',
  direction: 'row',
  width: '100%',
  justifyContent: 'space-between',
};

const sectionCss = {
  color: '#000',
  paddingTop: '20px',
  paddingBottom: '20px',
};

const mainInfoContainerCss = {width: '100%'};

const mainInfoItemCss = {
  width: '50%',
};

const assignToCss = {
  fontWeight: 900,
};

const paddingCss = {
  paddingTop: '20px',
};

const labelCss = (theme: Theme) => ({
  color: theme.palette.primary.main,
  width: '13rem',
});

const labelSecondGridCss = (theme: Theme) => ({
  color: theme.palette.primary.main,
  width: '20rem',
});

const mainGridCss = {
  flexGrow: 1,
  backgroundColor: '#fff',
  width: {xs: '95vw', md: '70vw'},
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '30px',
  padding: {xs: '1em', md: '3em'},
  alignSelf: 'center',
};

const otherInfoTextGridCss = {
  display: 'flex',
  direction: 'row',
  width: '100%',
  justifyContent: 'space-between',
};

export default Index;
