import React, {memo} from 'react';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import {useAuthContext} from '../../../contexts/useAuthContext/useAuthContext';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {useGetUserProfilePitcure} from '../../../service/UserService/useUserService';

const drawerWidth = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isMediumDevice: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isMediumDevice',
})<AppBarProps>(({theme, open, isMediumDevice}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    !isMediumDevice && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

interface Props {
  open: boolean;
  handleOpenSideBar: () => void;
  isMediumDevice: boolean;
}
const Index: React.FC<Props> = ({open, handleOpenSideBar, isMediumDevice}) => {
  const {user, isAdmin, logout, isCheckingCredetials} = useAuthContext();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id: currentUserId} = user;
  const {data: pictureUrl} = useGetUserProfilePitcure(currentUserId);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutClick = () => {
    handleCloseUserMenu();
    logout();
  };

  const handleAdministrationClick = () => {
    handleCloseUserMenu();
    navigate('/administration');
  };

  const handleRegistrationClick = () => {
    handleCloseUserMenu();
    navigate('/registration');
  };

  const handleProfileClick = () => {
    handleCloseUserMenu();
    navigate('/profile');
  };

  const hamburgerIconCss = {
    mr: 2,
    backgroundColor: '#f4f9fd',
    color: '#000',
    ...(open && {display: 'none'}),
  };

  return (
    <AppBar
      isMediumDevice={isMediumDevice}
      position="fixed"
      open={open}
      sx={{backgroundColor: '#f4f9fd'}}
      elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{backgroundColor: '#f4f9fd'}}>
          <Box sx={hamburgerBoxCss}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenSideBar}
              edge="start"
              sx={hamburgerIconCss}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={profileInfoCss}>
            <Tooltip title={t('appbarTooltip')}>
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                <Avatar alt="Profile picture" srcSet={pictureUrl} />
              </IconButton>
            </Tooltip>
            <Typography variant="h4" sx={usernameCSS}>
              {user.username}
            </Typography>
            <Menu
              sx={{mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              slotProps={{paper: {sx: {borderRadius: '20px'}}}}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <MenuItem onClick={handleProfileClick}>{t('profile')}</MenuItem>
              {!isCheckingCredetials && isAdmin && (
                <MenuItem onClick={handleRegistrationClick}>
                  {t('userRegistration')}
                </MenuItem>
              )}
              {!isCheckingCredetials && isAdmin && (
                <MenuItem onClick={handleAdministrationClick}>
                  {t('userAdministration')}
                </MenuItem>
              )}
              <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
const usernameCSS = {
  paddingLeft: '10px',
  paddingTop: '8px',
  color: '#000',
};

const profileInfoCss = {
  display: 'flex',
  flexGrow: 0,
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

const hamburgerBoxCss = {
  display: 'flex',
  flexGrow: 1,
};

export default memo(Index);
