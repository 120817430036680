import axios, {AxiosError} from 'axios';
import {getFromStorage, storage} from '../utils/storageUtils';
import {ErrorResponse, SuccessResponse} from './types';
import {API_CODES} from '../validation/apiCodes';
import {TFunction} from 'i18next';

const apiUrl = process.env.REACT_APP_API_URL;

export const httpsNotAuthorized = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

export const httpsAuthorized = axios.create({
  baseURL: apiUrl,
});

export const httpsProfilePicture = axios.create({
  baseURL: apiUrl,
});

export const httpsPdf = axios.create({
  baseURL: apiUrl,
});

httpsAuthorized.interceptors.request.use(
  config => {
    config.headers['Authorization'] =
      `${getFromStorage<string>(storage.TOKEN_TYPE)} ${getFromStorage<string>(storage.TOKEN)}`;
    config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpsPdf.interceptors.request.use(
  config => {
    config.headers['Authorization'] =
      `${getFromStorage<string>(storage.TOKEN_TYPE)} ${getFromStorage<string>(storage.TOKEN)}`;
    config.headers['Content-Type'] = 'application/pdf';
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const getTranslationErrorCode = (error?: AxiosError<ErrorResponse>) => {
  // log error
  const code = error?.response?.data?.code || error?.response?.data?.title;
  if (!code || !API_CODES.includes(code.toUpperCase())) {
    return 'DEFAULT-ERROR-MESSAGE';
  }
  return code.toUpperCase();
};

export const getTranslationSuccessCode = (code?: string) => {
  if (!code || !API_CODES.includes(code.toUpperCase())) {
    return 'DEFAULT-SUCCESS-MESSAGE';
  }
  return code.toUpperCase();
};

export const httpGet = async <T>(api: string, t: TFunction) => {
  try {
    const {data: requestData} = await httpsAuthorized.get<T>(api);
    return requestData;
  } catch (error) {
    return Promise.reject(
      new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
    );
  }
};

export const httpGetPDF = async <T>(api: string, t: TFunction) => {
  try {
    const {data: requestData} = await httpsPdf.get<T>(api, {
      responseType: 'blob',
    });
    return requestData;
  } catch (error) {
    return Promise.reject(
      new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
    );
  }
};

export const httpPut = async <T>(api: string, putData: T, t: TFunction) => {
  try {
    const {data} = await httpsAuthorized.put<SuccessResponse>(api, putData);
    return t(getTranslationSuccessCode(data.title));
  } catch (error) {
    return Promise.reject(
      new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
    );
  }
};

export const httpPost = async <T>(
  api: string,
  postData: T,
  t: TFunction,
  returnMessage = true,
) => {
  try {
    const {data} = await httpsAuthorized.post<any>(api, postData);
    if (returnMessage) {
      return t(getTranslationSuccessCode(data.title));
    }
    return data;
  } catch (error) {
    return Promise.reject(
      new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
    );
  }
};

export const httpDelete = async (api: string, t: TFunction) => {
  try {
    const {data} = await httpsAuthorized.delete<SuccessResponse>(api);
    return t(getTranslationSuccessCode(data.title));
  } catch (error) {
    return Promise.reject(
      new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
    );
  }
};
