import React from 'react';
import Box from '@mui/system/Box';
import {Dialog, buttonType} from '../..';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';

interface Props {
  title: string;
  message: string;
  open: boolean;
  handleDelete: () => void;
  handleClose: () => void;
}

const Index: React.FC<Props> = ({
  title,
  open,
  message,
  handleDelete,
  handleClose,
}) => {
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      title={title}
      fullWidth={true}
      secondButtonTitle={t('delete')}
      secondButtonType={buttonType.error}
      hideFirstButtonButton={true}
      handleClose={handleClose}
      handleSecondButtonClick={handleDelete}>
      <Box>
        <Typography sx={messageCss}>{message}</Typography>
      </Box>
    </Dialog>
  );
};
const messageCss = {paddingLeft: '25px'};
export default Index;
