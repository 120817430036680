import * as React from 'react';
import {
  GridRenderCellParams,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import {UserModal, UserRolesModal} from '../../components';
import {
  getAllUsers,
  useUpdateUserforAdminById,
} from '../../service/UserService/useUserService';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Table, TableCell} from '../../components';
import Grid from '@mui/material/Grid';
import {User} from '../../service/UserService/types';
import {Status} from '../../service/types';

const Index = () => {
  const {t} = useTranslation();
  const [selectedUserId, setSelectedUserId] = React.useState<
    number | undefined
  >();
  const [selectedUserRolesId, setSelectedUserRolesId] = React.useState<
    number | undefined
  >();
  const mediumDevices = useMediaQuery('(max-width:1305px)');
  const {updateAdminUserById, updateStatus} =
    useUpdateUserforAdminById(selectedUserId);

  const handleUpdate = async (formData: User) => {
    await updateAdminUserById(formData);
  };

  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: '',
      renderCell: () => (
        <Avatar
          alt="Profile picture"
          src=""
          sx={{width: mediumDevices ? 40 : 70, height: mediumDevices ? 40 : 70}}
        />
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 50,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'fullName',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          firstText={`${params?.row?.firstName} ${params?.row?.lastName}`}
          secondText={params?.row?.email}
          isFirstTextBold={true}
        />
      ),
      headerName: t('administrationLastName'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 180,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'position',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          firstText={t('administrationPosition')}
          secondText={params?.row?.position}
          isReplaceColors={true}
          isFirstTextBold={true}
        />
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 180,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      minWidth: 100,
      maxWidth: 250,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={React.useId()}
          icon={<ModeEditIcon />}
          onClick={() => {
            setSelectedUserId(params.id as number);
          }}
          label={t('administrationEditUser')}
          showInMenu
        />,
        <GridActionsCellItem
          key={React.useId()}
          icon={<ModeEditIcon />}
          onClick={() => {
            setSelectedUserRolesId(params.id as number);
          }}
          label={t('administrationEditRoles')}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid
        item
        sx={{
          alignSelf: 'center',
        }}>
        <Typography variant="h1">{t('administrationTitle')}</Typography>
      </Grid>
      <Grid item display={'flex'} sx={mainGridCss}>
        <Table
          queryFn={getAllUsers}
          columns={columns}
          tableUpdateRow={{
            status: updateStatus as Status,
            afterSuccessCallback: () => setSelectedUserId(undefined),
          }}
        />
        {selectedUserId && (
          <UserModal
            id={selectedUserId}
            open={true}
            title={t('admDialogEditUserTitle')}
            handleClose={() => {
              setSelectedUserId(undefined);
            }}
            handleUpdate={handleUpdate}
          />
        )}
        {selectedUserRolesId && (
          <UserRolesModal
            id={selectedUserRolesId}
            open={true}
            title={t('admDialogEditRolesTitle')}
            handleClose={() => setSelectedUserRolesId(undefined)}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mainGridCss = {
  width: {sm: '100%', md: '75vw'},
  alignSelf: 'center',
};

export default Index;
