import {
  httpsNotAuthorized,
  httpsAuthorized,
  getTranslationErrorCode,
} from '../common';
import {useMutation, useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {SuccessResponse} from '../types';
import {ChangePassword} from './types';

export const useResetPassword = (email: string) => {
  const {t} = useTranslation('api');

  const {refetch: resetPassword, data: isSend} = useQuery({
    queryKey: ['resetPassword'],
    queryFn: async () => {
      try {
        await httpsNotAuthorized.post<SuccessResponse>(
          `auth/forgot-password?email=${email}`,
        );
        return true;
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    enabled: false,
    retry: 0,
  });

  return {
    resetPassword,
    isSend,
  };
};

export const useChangePassword = () => {
  const {t} = useTranslation('api');

  const {
    mutate: changeCurrentPassword,
    data,
    isSuccess: isPasswordChanged,
  } = useMutation({
    mutationFn: async (value: ChangePassword) => {
      try {
        await httpsAuthorized.post<SuccessResponse>(
          'auth/change-password',
          value,
        );
        return t('DEFAULT-SUCCESS-MESSAGE');
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    retry: 0,
  });

  return {
    changeCurrentPassword,
    data,
    isPasswordChanged,
  };
};
