import React from 'react';
import Box from '@mui/material/Box';
import {Dialog} from '../..';
import {MuiFileInput} from 'mui-file-input';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InputLabel from '@mui/material/InputLabel';
import {useTranslation} from 'react-i18next';

interface Props {
  open: boolean;
  title: string;
  isButtonDisabled?: boolean;
  isMultiple?: boolean;
  imageFileTypeOnly?: boolean;
  handleClose: (refetch: boolean) => void;
  handleUpload?: (file: File | File[]) => void;
}

const Index: React.FC<Props> = ({
  open,
  title,
  isMultiple = false,
  imageFileTypeOnly = false,
  isButtonDisabled = false,
  handleClose,
  handleUpload,
}) => {
  const {t} = useTranslation();
  const [value, setValue] = React.useState(null);
  const isImageTypeError =
    !isMultiple && imageFileTypeOnly && value && !value.type.match('image.*');

  const handleChange = newValue => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      title={title}
      fullWidth={false}
      hideFirstButtonButton={true}
      secondButtonDisabled={isButtonDisabled || isImageTypeError}
      secondButtonTitle={t('fileUploadBtn')}
      handleClose={() => handleClose(false)}
      handleSecondButtonClick={() => handleUpload(value)}>
      <Box>
        <MuiFileInput
          placeholder={t('fileUploadPlaceholder')}
          multiple={isMultiple}
          value={value}
          onChange={handleChange}
          sx={{width: '100%'}}
          InputProps={{
            inputProps: imageFileTypeOnly ? {accept: '.png, .jpg, .jpeg'} : {},
            startAdornment: <AttachFileIcon />,
          }}
          clearIconButtonProps={{
            title: t('fileUploadRemoveBtn'),
            children: <CloseIcon fontSize="small" />,
          }}
        />
        {isImageTypeError && (
          <InputLabel sx={errorCss}>{t('fileUploadImageTypeError')}</InputLabel>
        )}
      </Box>
    </Dialog>
  );
};

const errorCss = {
  paddingTop: '10px',
  paddingLeft: '5px',
  color: 'red',
};
export default Index;
