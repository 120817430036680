import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog, TextArea, Select} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {Device} from '../../../service/DevicesService/types';
import {useGetClientListModal} from '../../../service/ClientService/useClientService';
import {SelectItem} from '../../common/Select/types';
import {Client} from '../../../service/ClientService/types';

interface Props {
  id?: string;
  data?: Device;
  client?: SelectItem;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (formData: Device) => void;
}

const Index: React.FC<Props> = ({
  data,
  title,
  open,
  client,
  id = 'device-modal',
  handleSubmit,
  handleClose,
}) => {
  const {t} = useTranslation();
  const {data: clientList} = useGetClientListModal();

  const initData = {
    name: '',
    description: '',
    client: !client ? null : client,
  };

  const validations = {
    name: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => value.length <= 100,
          message: t('deviceModalNameLengthValidation'),
        },
      ],
    },
    description: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => value.length <= 500,
          message: t('deviceModalDescriptionLengthValidation'),
        },
      ],
    },
    client: {required: {value: true}},
  };

  const {
    formData,
    handleInputChange,
    handleTextAreaChange,
    handleSelectChange,
    formErrors,
    isValidForm,
    setFormData,
  } = useForm<Device>(initData, validations);

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        client: {
          value: (data?.client as Client)?.id.toString(),
          name: (data?.client as Client)?.name,
        },
      });
    }
  }, [data]);

  const onSubmit = (data: Device) => {
    const parseData = {
      ...data,
      client: {id: (data?.client as SelectItem)?.value},
    };
    handleSubmit(parseData);
  };

  return (
    <Dialog
      id={id}
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={!isValidForm}
      handleClose={handleClose}
      secondButtonTitle={!data ? t('createBtn') : t('updateBtn')}
      handleSecondButtonClick={() => onSubmit(formData)}>
      <Box>
        <Input
          id="device-modal-name"
          name="name"
          label={t('devicesModalName')}
          initialValue={formData.name}
          onChange={handleInputChange}
          errorText={formErrors.name || ''}
        />
        <TextArea
          id="device-modal-description"
          name="description"
          label={t('devicesModalDescription')}
          initialValue={formData?.description || ''}
          onChange={handleTextAreaChange}
          errorText={formErrors?.description || ''}
        />
        <Select
          id="device-modal-client"
          name="client"
          items={clientList}
          label={t('devicesModalClient')}
          value={formData?.client as SelectItem}
          onChange={handleSelectChange}
          errorText={formErrors?.client || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
