import {httpGet, httpPut} from '../common';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {Roles} from './types';

export const useGetAllRoles = () => {
  const {t} = useTranslation('api');
  const {
    refetch: getAllRoles,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['allRoles'],
    queryFn: async () => {
      return await httpGet<Roles>('user-role/all', t);
    },
    retry: 0,
  });

  return {
    getAllRoles,
    data,
    isLoading,
  };
};

export const useGetRolesById = (id: number) => {
  const {t} = useTranslation('api');
  const {data, isLoading} = useQuery({
    queryKey: ['rolesById', id],
    queryFn: async () => {
      return await httpGet<Roles>(`user-role/${id}`, t);
    },
    retry: 0,
  });

  return {
    data,
    isLoading,
  };
};

export const useUpdateRolesById = (id: number, formData: Roles) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateRolesById,
    data,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async () => {
      return await httpPut<Roles>(`user-role/update/${id}`, formData, t);
    },
    retry: 0,
  });

  return {
    updateRolesById,
    data,
    isPending,
    isSuccess,
  };
};
