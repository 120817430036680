import React from 'react';
import {useRouteError} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

const Index = () => {
  const {t} = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: 'repeat(2, 1fr)'}}>
      <Box sx={{height: '40vh'}}></Box>
      <Box
        sx={{display: 'flex', flexDirection: 'column', justifyContent: 'top'}}>
        <Typography variant="h3" align="center">
          Oops!
        </Typography>
        <Typography variant="h3" align="center">
          {t('404Page')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Index;
