import {
  httpsNotAuthorized,
  getTranslationErrorCode,
  getTranslationSuccessCode,
  httpPost,
} from '../common';
import {useQuery} from '@tanstack/react-query';
import {LoginCredentials, LoginResponse, SignUp} from './types';
import {setStorage, storage} from '../../utils/storageUtils';
import {useTranslation} from 'react-i18next';
import {SuccessResponse} from '../types';

export const useAuth = (credentials: LoginCredentials) => {
  const {t} = useTranslation('api');

  const {refetch: checkAuth} = useQuery({
    queryKey: ['setAuth'],
    queryFn: async () => {
      try {
        const {data} = await httpsNotAuthorized.post<LoginResponse>(
          'auth/signin',
          JSON.stringify(credentials),
        );
        setStorage(storage.TOKEN, data.accessToken);
        setStorage(storage.TOKEN_TYPE, data.tokenType);
        return true;
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    enabled: false,
    retry: 0,
  });

  return {
    checkAuth,
  };
};

export const useLogout = () => {
  const {t} = useTranslation('api');
  const {refetch: logoutUser, isLoading} = useQuery({
    queryKey: ['logout'],
    queryFn: async () => {
      return await httpPost('auth/signout', {}, t);
    },
    enabled: false,
    retry: 0,
  });

  return {
    logoutUser,
    isLoading,
  };
};

export const useSignUp = (formData: SignUp) => {
  const {t} = useTranslation('api');
  const {
    refetch: signUp,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['signUp'],
    queryFn: async () => {
      try {
        const {data} = await httpsNotAuthorized.post<SuccessResponse>(
          'auth/signup',
          JSON.stringify(formData),
        );
        return t(getTranslationSuccessCode(data.title));
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    enabled: false,
    retry: 0,
  });

  return {
    signUp,
    isLoading,
    isSuccess,
  };
};
