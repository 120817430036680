export enum storage {
  USER = 'user',
  TOKEN = 'token',
  TOKEN_TYPE = 'token_type',
  EXP_DATE = 'exp',
  IAT_DATE = 'iat',
}

export const getFromStorage = <T>(key: string): T => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const setStorage = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};
