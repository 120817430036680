import {httpDelete, httpPost, httpPut} from '../common';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {WorkOrderItem, WorkOrderItems} from './types';

export const useDeleteWorkOrderItem = (id: number) => {
  const {t} = useTranslation('api');
  const {mutate: deleteWorkOrderItem, status: deleteStatus} = useMutation({
    mutationFn: async () => {
      return await httpDelete(`work-order-item/${id}`, t);
    },
    retry: 0,
  });

  return {
    deleteWorkOrderItem,
    deleteStatus,
  };
};

export const useCreateWorkOrderItem = (WorkOrderId: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: createWorkOrderItem,
    data,
    isSuccess: isSuccessCreate,
  } = useMutation({
    mutationFn: async (value: WorkOrderItems) => {
      return await httpPost<WorkOrderItems>(
        `work-order-item/${WorkOrderId}`,
        value,
        t,
      );
    },
    retry: 0,
  });

  return {
    createWorkOrderItem,
    data,
    isSuccessCreate,
  };
};

export const useUpdateWorkOrderItem = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateWorkOrderItem,
    data,
    status: updateStatus,
  } = useMutation({
    mutationFn: async (value: WorkOrderItem) => {
      return await httpPut<WorkOrderItem>(`work-order-item/${id}`, value, t);
    },
    retry: 0,
  });

  return {
    updateWorkOrderItem,
    data,
    updateStatus,
  };
};
