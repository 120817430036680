import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';

interface props {
  children: React.ReactNode;
  adminOnlyWhenSignIn?: boolean;
  adminOnly?: boolean;
}
const Index: React.FC<props> = ({
  children,
  adminOnlyWhenSignIn = false,
  adminOnly = false,
}) => {
  const {user, isAdmin, isCheckingCredetials} = useAuthContext();

  if (adminOnlyWhenSignIn) {
    if (!isCheckingCredetials && user && !isAdmin) {
      return <Navigate to={location.pathname} />;
    }
    return <>{children}</>;
  }
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (adminOnly && user && !isAdmin) {
    return <Navigate to={location.pathname} />;
  }
  return <>{children}</>;
};

export default Index;
