import React, {forwardRef} from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {InputType, SXProps} from './types';
import {useTheme} from '@mui/material';

interface Props {
  id: string;
  name: string;
  initialValue: string;
  compareValue?: string;
  label?: string;
  type?: string | undefined;
  placeholder?: string;
  disabled?: boolean;
  sx?: SXProps;
  errorText?: string;
  onClick?: (name: string, value: string) => void;
  onChange?: (name: string, value: string, compareValue: string) => void;
}

const Index = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      name,
      initialValue,
      compareValue,
      label,
      type = InputType.TEXT,
      placeholder,
      disabled,
      sx,
      errorText,
      onClick,
      onChange,
    }: Props,
    ref,
  ) => {
    const showAdorment = type === InputType.PASSWORD;
    const [showPassword, setShowPassword] = React.useState(
      type === InputType.PASSWORD ? false : true,
    );
    const passwordShow = showPassword ? 'text' : 'password';
    const theme = useTheme();
    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };

    const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
      if (onClick) {
        onClick(name, e.currentTarget.value);
      }
    };

    return (
      <Box py={2} px={2} sx={sx?.box}>
        <Typography sx={typographyCss}>{label}</Typography>
        <OutlinedInput
          inputRef={ref}
          fullWidth={true}
          id={id}
          error={errorText ? true : false}
          type={type != InputType.PASSWORD ? type : passwordShow}
          value={initialValue}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(name, e.target.value, compareValue);
          }}
          onClick={handleClick}
          placeholder={placeholder}
          sx={{...inputCss, ...sx?.input}}
          endAdornment={
            showAdorment && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }
        />
        {errorText && (
          <Typography sx={[errorTextCss, {color: theme.palette.error.light}]}>
            {errorText}
          </Typography>
        )}
      </Box>
    );
  },
);
const typographyCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  color: 'gray',
  paddingLeft: '5px',
};

const inputCss = {
  display: 'flex',
  flexGrow: 1,
  borderRadius: '15px',
};

const errorTextCss = {
  padding: '7px',
};

Index.displayName = 'Input';
export default Index;
