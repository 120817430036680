import React from 'react';
import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

interface Props {
  id: string;
  name: string;
  initialValue: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  errorText?: string;
  rows?: number;
  onChange?: (name: string, value: string) => void;
}

const Index: React.FC<Props> = ({
  id,
  name,
  initialValue,
  errorText,
  label,
  rows = 10,
  placeholder = '',
  disabled = false,
  onChange,
}) => {
  const theme = useTheme();
  return (
    <Box py={2} px={2}>
      {label && <Typography sx={typographyCss}>{label}</Typography>}
      <TextField
        rows={rows}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        value={initialValue}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange(name, e.target.value);
        }}
        multiline={true}
        sx={{width: '100%'}}
        InputProps={{sx: {borderRadius: '30px'}}}
      />
      {errorText && (
        <Typography sx={[errorTextCss, {color: theme.palette.error.light}]}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

const typographyCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  color: 'gray',
  paddingLeft: '5px',
};

const errorTextCss = {
  padding: '7px',
};

export default Index;
