import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Logout = () => {
  const navigate = useNavigate();
  const {logout} = useAuthContext();
  const {t} = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      logout();
      navigate('/login', {replace: true});
    }, 3000);
  }, []);

  return (
    <Box sx={{display: 'grid', gridTemplateRows: 'repeat(2, 1fr)'}}>
      <Box sx={{height: '40vh'}}></Box>
      <Typography variant="h3" align="center">
        {t('logoutMessage')}
      </Typography>
    </Box>
  );
};

export default Logout;
