import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {
  LoginPage,
  ErrorPage,
  HomePage,
  LogoutPage,
  RegistrationPage,
  AdministrationPage,
  UserProfilePage,
  ClientPage,
  ClientsPage,
  DevicesPage,
  DevicePage,
  WorkOrderPage,
  WorkOrdersPage,
  PasswordResetPage,
} from '../../pages';
import {ProtectedRoute} from '..';

const Index = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/login"
        element={<LoginPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/password-reset"
        element={<PasswordResetPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/logout"
        element={<LogoutPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/registration"
        element={
          <ProtectedRoute adminOnlyWhenSignIn={true}>
            <RegistrationPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/administration"
        element={
          <ProtectedRoute adminOnly={true}>
            <AdministrationPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <UserProfilePage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <ClientsPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/clients/:id"
        element={
          <ProtectedRoute>
            <ClientPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/devices"
        element={
          <ProtectedRoute>
            <DevicesPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/devices/:id"
        element={
          <ProtectedRoute>
            <DevicePage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/workorders"
        element={
          <ProtectedRoute>
            <WorkOrdersPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/workorders/:id"
        element={
          <ProtectedRoute>
            <WorkOrderPage />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      />
    </Routes>
  );
};

export default Index;
