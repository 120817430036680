import {httpDelete, httpGet, httpPost, httpPut, httpGetPDF} from '../common';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {WorkOrder, WorkOrderList} from './types';
import {PaginationModel, TableData} from '../types';
import {TFunction} from 'i18next';

export const getWorkOrders = async (
  t: TFunction,
  paginationModel: PaginationModel,
) => {
  const queryParams = `?page=${paginationModel.page + 1}&size=${paginationModel.pageSize}`;
  const result = await httpGet<WorkOrderList>(
    `work-order/all${queryParams}`,
    t,
  );
  return result as TableData;
};

export const useGetWorkOrder = (id: string) => {
  const {t} = useTranslation('api');
  const {
    refetch: getWorkOrder,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['WorkOrder', id],
    queryFn: async () => {
      return await httpGet<WorkOrder>(`work-order/${id}`, t);
    },
    retry: 0,
  });

  return {
    getWorkOrder,
    data,
    isLoading,
  };
};

export const useUpdateWorkOrder = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateWorkOrder,
    data,
    isPending,
    isSuccess: isSuccessUpdateWO,
    status: updateStatus,
  } = useMutation({
    mutationFn: async (value: WorkOrder) => {
      return await httpPut<WorkOrder>(`work-order/${id}`, value, t);
    },
    retry: 0,
  });

  return {
    updateWorkOrder,
    data,
    isPending,
    isSuccessUpdateWO,
    updateStatus,
  };
};

export const useCreateWorkOrder = () => {
  const {t} = useTranslation('api');
  const {
    mutate: createWorkOrder,
    data,
    status: createStatus,
  } = useMutation({
    mutationFn: async (value: WorkOrder) => {
      return await httpPost<WorkOrder>('work-order', value, t);
    },
    retry: 0,
  });

  return {
    createWorkOrder,
    data,
    createStatus,
  };
};

export const useDeleteWorkOrder = (id: number) => {
  const {t} = useTranslation('api');
  const {mutate: deleteWorkOrder, status: deleteStatus} = useMutation({
    mutationFn: async () => {
      return await httpDelete(`work-order/${id}`, t);
    },
    retry: 0,
  });

  return {
    deleteWorkOrder,
    deleteStatus,
  };
};

export const useGetWOReport = () => {
  const {t} = useTranslation('api');
  const {data, mutate: getWOReport} = useMutation({
    mutationFn: async (value: number) => {
      if (value) {
        const res = await httpGetPDF<ArrayBuffer>(
          `work-order/report/${value}`,
          t,
        );
        if (res) {
          const blob = new Blob([res], {type: 'application/pdf'});
          const url = URL.createObjectURL(blob);
          window.open(url);
        }
      }
      return '';
    },
    retry: 0,
  });

  return {
    data,
    getWOReport,
  };
};
