import React, {useState} from 'react';
import {
  Table,
  TableCell,
  DeleteModal,
  Button,
  Input,
  DeviceModal,
} from '../../components';
import {useTranslation} from 'react-i18next';
import {
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import {
  getDevices,
  useDeleteDeviceById,
  useCreateDevice,
} from '../../service/DevicesService/useDeviceService';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {Status} from '../../service/types';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

const Index = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {isAdmin} = useAuthContext();

  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const {deleteDeviceById, deleteStatus} = useDeleteDeviceById(selectedId);
  const {createDevice, createStatus} = useCreateDevice();

  let columns: GridColDef[] = [
    {
      field: 'name',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          showTooltip={true}
          firstText={t('devicesTableName')}
          secondText={params?.row?.name}
          isReplaceColors={true}
          isFirstTextBold={true}
          sx={{main: {paddingLeft: '30px'}}}
        />
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 180,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'description',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          showTooltip={true}
          firstText={t('devicesTableDescription')}
          secondText={params?.row?.description}
          isReplaceColors={true}
          isFirstTextBold={true}
          sx={{main: {paddingLeft: '30px'}}}
        />
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 180,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  if (isAdmin) {
    columns = [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        minWidth: 100,
        maxWidth: 250,
        renderCell: (params: GridRenderCellParams) => (
          <IconButton onClick={() => setSelectedId(params?.row?.id)}>
            <DeleteIcon color={'primary'} />
          </IconButton>
        ),
      },
    ];
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/devices/${params?.row?.id}`);
  };

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid item sx={titleCss}>
        <Typography variant="h1">{t('devicesTableTitle')}</Typography>
      </Grid>
      <Grid container display={'flex'} sx={mainGridCss} direction={'column'}>
        <Grid
          container
          display={'flex'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Grid item sx={buttonGridCss}>
            <Grid
              container
              display={'flex'}
              direction={'row'}
              alignItems={'center'}>
              <Grid item>
                <Input
                  id="search-clients-input"
                  name="search"
                  sx={{box: {paddingRight: 0}}}
                  initialValue={searchText}
                  onChange={(name, value) => setSearchText(value)}
                />
              </Grid>
              <Grid item>
                <Button
                  id="search-client-button"
                  startIcon={<SearchIcon />}
                  text={t('search')}
                  onClick={() => setIsSearch(true)}
                />
              </Grid>
            </Grid>
          </Grid>
          {isAdmin && (
            <Grid item alignSelf={'flex-end'} sx={buttonGridCss}>
              <Button
                id="add-device"
                startIcon={<AddIcon />}
                text={t('addDeviceBtn')}
                onClick={() => setOpenDeviceModal(true)}
              />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Table
            queryFn={getDevices}
            columns={columns}
            onRowClick={handleRowClick}
            searchCriteria={searchText}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
            tableDeleteRow={{
              status: deleteStatus as Status,
              afterSuccessCallback: () => setSelectedId(undefined),
            }}
            tableCreateRow={{
              status: createStatus as Status,
              afterSuccessCallback: () => setOpenDeviceModal(false),
            }}
          />
        </Grid>
      </Grid>
      {selectedId && isAdmin && (
        <DeleteModal
          title={t('deleteDeviceTitle')}
          message={t('deleteDeviceMessage')}
          open={selectedId ? true : false}
          handleDelete={deleteDeviceById}
          handleClose={() => {
            setSelectedId(undefined);
          }}
        />
      )}
      {openDeviceModal && isAdmin && (
        <DeviceModal
          id="device-add-modal"
          title={t('deviceModalAddTitle')}
          open={openDeviceModal}
          handleClose={() => {
            setOpenDeviceModal(false);
          }}
          handleSubmit={createDevice}
        />
      )}
    </Grid>
  );
};

const mainGridCss = {
  width: {xs: '95vw', md: '70vw'},
  alignSelf: 'center',
};

const titleCss = {
  alignSelf: 'center',
};

const buttonGridCss = {paddingRight: '50px'};

export default Index;
