import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Dialog} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {
  useGetAllRoles,
  useGetRolesById,
  useUpdateRolesById,
} from '../../../service/UserRolesService/useUserRolesService';
import {GroupedCheckbox} from '../..';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FormCheckbox} from '../../common/GroupedCheckbox/types';
import {ROLES} from '../../../types';
import {useAuthContext} from '../../../contexts/useAuthContext/useAuthContext';

interface Props {
  id: number;
  title: string;
  open: boolean;
  handleClose: () => void;
}

type RolesFormData = {
  roles: FormCheckbox[];
};

const Index: React.FC<Props> = ({id, title, open, handleClose}) => {
  const {t} = useTranslation();
  const {user} = useAuthContext();
  const {id: currentUserId} = user;
  const {data, isLoading} = useGetRolesById(id);
  const {data: roles, isLoading: isPendingData} = useGetAllRoles();

  const initData = {
    roles: [],
  };

  const validations = {
    roles: {
      custom: [
        {
          isValid: (roles: FormCheckbox[]) => roles?.some(r => r.checked),
          message: t('rolesDialogValidation'),
        },
      ],
    },
  };

  const {
    formData,
    handleGroupedCheckboxes,
    formErrors,
    isValidForm,
    setFormData,
  } = useForm<RolesFormData>(initData, validations);

  const {
    isPending: isPendingSubmit,
    updateRolesById,
    isSuccess,
  } = useUpdateRolesById(
    id,
    formData.roles
      .filter((r: FormCheckbox) => r.checked)
      .map((r: FormCheckbox) => r.value),
  );

  useEffect(() => {
    if (roles) {
      setFormData({
        roles: roles.map(r => {
          return {
            value: r,
            label: t(r, {ns: 'api'}),
            checked: data?.includes(r) ? true : false,
            disabled: r === ROLES.ADMIN && id === Number(currentUserId),
          };
        }),
      });
    }
  }, [roles, data]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Dialog
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={
        !isValidForm ||
        isLoading ||
        isPendingSubmit ||
        isPendingData ||
        formData.roles.length === 0
      }
      handleClose={handleClose}
      handleSecondButtonClick={updateRolesById}>
      <Box>
        <GroupedCheckbox
          id="roles-grouped-checkboxes"
          groupLabel={t('admDialogEditRolesLabel')}
          errorText={formErrors?.roles || ''}>
          {formData?.roles.map((r: FormCheckbox) => (
            <FormControlLabel
              key={r.value}
              control={
                <Checkbox
                  checked={r.checked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleGroupedCheckboxes('roles', e)
                  }
                  value={r.value}
                  name={r.label}
                  disabled={r.disabled}
                />
              }
              label={r.label}
            />
          ))}
        </GroupedCheckbox>
      </Box>
    </Dialog>
  );
};

export default Index;
