import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import {Link} from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

interface Props {
  textColor?: string;
  isIconWhite?: boolean;
}
const NavigationItems: React.FC<Props> = ({textColor, isIconWhite = false}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const linkCss = {
    textDecoration: 'none',
    color: !textColor ? theme.palette.primary.main : textColor,
  };

  return (
    <List>
      <Link to={'/'} style={linkCss}>
        <ListItem key={'dashboard'}>
          <ListItemButton>
            <ListItemIcon>
              <SpaceDashboardIcon color={isIconWhite ? 'info' : 'primary'} />
            </ListItemIcon>
            <ListItemText primary={t('dashboard')} />
          </ListItemButton>
        </ListItem>
      </Link>

      <Link to={'/clients'} style={linkCss}>
        <ListItem key={'clients'}>
          <ListItemButton>
            <ListItemIcon>
              <GroupsIcon color={isIconWhite ? 'info' : 'primary'} />
            </ListItemIcon>
            <ListItemText primary={t('clients')} />
          </ListItemButton>
        </ListItem>
      </Link>

      <Link to={'/devices'} style={linkCss}>
        <ListItem key={'devices'}>
          <ListItemButton>
            <ListItemIcon>
              <AgricultureIcon color={isIconWhite ? 'info' : 'primary'} />
            </ListItemIcon>
            <ListItemText primary={t('devices')} />
          </ListItemButton>
        </ListItem>
      </Link>

      <Link to={'/workorders'} style={linkCss}>
        <ListItem key={'workorders'}>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIndIcon color={isIconWhite ? 'info' : 'primary'} />
            </ListItemIcon>
            <ListItemText primary={t('workorders')} />
          </ListItemButton>
        </ListItem>
      </Link>
    </List>
  );
};

export default NavigationItems;
