import React, {useEffect, useRef} from 'react';
import {Input, InputType, Button} from '../../components';
import {Grid, Box, Typography, useTheme} from '@mui/material';
import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useForm from '../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {useSignUp} from '../../service/AuthService/useAuthService';
import {useNavigate} from 'react-router-dom';
import {
  isValidEmailFormat,
  isValidLength,
  isStrongEnough,
} from '../../validation/appValidations';
import {ROLES} from '../../types';
import {SignUp} from '../../service/AuthService/types';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';

const Index: React.FC = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const firstNameinput = useRef<HTMLInputElement>();
  const navigate = useNavigate();
  const {user, isAdmin} = useAuthContext();

  const initData = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    role: [ROLES.USER],
  };

  const isRequired = {required: {value: true}};

  const validations = {
    firstName: isRequired,
    lastName: isRequired,
    username: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => isValidLength(value, 6, 20),
          message: t('usernameValidation'),
        },
      ],
    },
    email: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => isValidEmailFormat(value),
          message: t('emailValidation'),
        },
      ],
    },
    password: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => isValidLength(value, 8),
          message: t('passLengthValidation'),
        },
        {
          isValid: (value: string) => isStrongEnough(value),
          message: t('passStrengthValidation'),
        },
      ],
    },
  };

  const {formData, handleInputChange, formErrors, isValidForm, resetFormData} =
    useForm<SignUp>(initData, validations);

  const {signUp, isLoading, isSuccess} = useSignUp(formData);

  useEffect(() => {
    if (isSuccess) {
      if (!user || (user && !isAdmin)) {
        navigate('/login', {replace: true});
      }
      resetFormData();
    }
  }, [isSuccess]);

  useEffect(() => {
    firstNameinput?.current?.focus();
  }, []);

  const formGridCss = {
    alignItems: 'stretch',
    width: {sm: '100vw', md: '50vw'},
    borderTopLeftRadius: isAdmin ? '20px' : {sm: '20px', md: '0'},
    borderBottomLeftRadius: isAdmin ? '20px' : {sm: '20px', md: '0'},
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
  };

  const mainGridCss = {
    alignItems: 'stretch',
    justifyContent: 'center',
    height: 'auto',
    width: 'auto',
    padding: {sx: '10px', sm: '10px', md: '20px'},
  };

  const formGridAdminCss = {
    flexGrow: 1,
    backgroundColor: '#fff',
    width: {xs: '95vw', md: '70vw'},
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '30px',
    padding: {xs: '1em', md: '3em'},
    alignSelf: 'center',
  };

  return (
    <Grid
      container
      sx={!isAdmin ? mainGridCss : {}}
      display={'flex'}
      direction={isAdmin ? 'column' : 'row'}>
      {!user && (
        <Grid
          item
          md={6}
          sx={[
            imageGridCss,
            {backgroundColor: theme.palette.primary.main},
          ]}></Grid>
      )}
      {isAdmin && (
        <Grid
          item
          sx={{
            alignSelf: 'center',
          }}>
          <Typography variant="h1">{t('registrationTitle')}</Typography>
        </Grid>
      )}
      <Grid
        item
        md={isAdmin ? 12 : 6}
        component={Paper}
        elevation={6}
        square
        sx={isAdmin ? formGridAdminCss : formGridCss}>
        <Box my={5} mx={5} sx={formContainerCss}>
          {!isAdmin && (
            <Typography
              sx={[signinTextCss, {color: theme.palette.primary.main}]}>
              {t('regText')}
            </Typography>
          )}
          <Box sx={formCss}>
            <Input
              ref={firstNameinput}
              id="input-first-name"
              name="firstName"
              label={t('regFirstName')}
              initialValue={formData.firstName}
              onChange={handleInputChange}
              errorText={formErrors?.firstName || ''}
            />
            <Input
              id="input-last-name"
              name="lastName"
              label={t('reglastName')}
              initialValue={formData.lastName}
              onChange={handleInputChange}
              errorText={formErrors?.lastName || ''}
            />
            <Input
              id="input-username"
              name="username"
              label={t('username')}
              initialValue={formData.username}
              onChange={handleInputChange}
              errorText={formErrors?.username || ''}
            />
            <Input
              id="input-email"
              name="email"
              label={'E-mail'}
              initialValue={formData.email}
              onChange={handleInputChange}
              errorText={formErrors?.email || ''}
            />
            <Input
              id="input-password"
              name="password"
              label={t('password')}
              initialValue={formData.password}
              onChange={handleInputChange}
              type={InputType.PASSWORD}
              errorText={formErrors?.password || ''}
            />
            <Box sx={buttonWrapperCss}>
              <Button
                id="signup-button"
                text={isAdmin ? t('regButtonAdmin') : t('regButton')}
                sx={buttonCss}
                endIcon={<ArrowForwardIcon />}
                disabled={!isValidForm || isLoading}
                onClick={signUp}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const imageGridCss = {
  alignItems: 'stretch',
  borderBottomLeftRadius: 20,
  borderTopLeftRadius: 20,
  width: {sm: '0', md: '50vw'},
};

const signinTextCss = {
  fontSize: '2em',
  display: 'flex',
  justifyContent: 'center',
};

const formContainerCss = {
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
};

const formCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
};

const buttonWrapperCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const buttonCss = {
  paddingLeft: '50px',
  paddingRight: '50px',
};
export default Index;
