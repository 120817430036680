import {
  getTranslationSuccessCode,
  getTranslationErrorCode,
  httpGet,
  httpPut,
  httpsProfilePicture,
  httpsAuthorized,
} from '../common';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {UserList, User} from './types';
import {SuccessResponse, PaginationModel, TableData} from '../types';
import {getFromStorage, storage} from '../../utils/storageUtils';
import {TFunction} from 'i18next';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import {SelectItem} from '../../components/common/Select/types';

const apiUrl = process.env.REACT_APP_API_URL;

export const getAllUsers = async (
  t: TFunction,
  paginationModel: PaginationModel,
) => {
  const queryParams = `?page=${paginationModel.page + 1}&size=${paginationModel.pageSize}`;
  const result = await httpGet<UserList>(`user/all${queryParams}`, t);
  return result as TableData;
};

export const useGetUserList = () => {
  const {t} = useTranslation('api');
  const {isAdmin} = useAuthContext();
  const {
    refetch: getUserList,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['UserList'],
    queryFn: async () => {
      try {
        const selectValues: SelectItem[] = [];
        if (isAdmin) {
          const {data} = await httpsAuthorized.get<UserList>('user/all');
          data?.content?.forEach((user: User) => {
            selectValues.push({
              label: `${user.firstName} ${user.lastName}`,
              value: user.id.toString(),
            });
          });
        } else {
          const {data} = await httpsAuthorized.get<User>('/user');
          selectValues.push({
            label: `${data.firstName} ${data.lastName}`,
            value: data.id.toString(),
          });
        }
        return selectValues;
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    retry: 0,
  });

  return {
    getUserList,
    data,
    isLoading,
  };
};

export const useGetUserforAdminById = (id: number) => {
  const {t} = useTranslation('api');
  const {
    data,
    isLoading,
    refetch: getUserForAdminById,
  } = useQuery({
    queryKey: ['userForAdminById', id],
    queryFn: async () => {
      return httpGet<User>(`/user/${id}`, t);
    },
    staleTime: 0,
    retry: 0,
  });

  return {
    getUserForAdminById,
    data,
    isLoading,
  };
};

export const useUpdateUserforAdminById = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateAdminUserById,
    data,
    status: updateStatus,
  } = useMutation({
    mutationFn: async (value: User) => {
      return await httpPut<User>(`/user/${id}`, value, t);
    },
    retry: 0,
  });

  return {
    updateAdminUserById,
    data,
    updateStatus,
  };
};

export const useGetUserById = (enabled = true) => {
  const {t} = useTranslation('api');
  const {
    data,
    isLoading,
    refetch: getUserProfile,
  } = useQuery({
    queryKey: ['userById'],
    queryFn: async () => {
      return await httpGet<User>('/user', t);
    },
    enabled: enabled,
    retry: 0,
  });

  return {
    data,
    isLoading,
    getUserProfile,
  };
};

export const useUpdateUserById = (formData: User) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateUserById,
    data,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async () => {
      return await httpPut<User>('/user', formData, t);
    },
    retry: 0,
  });

  return {
    updateUserById,
    data,
    isPending,
    isSuccess,
  };
};

export const useGetUserProfilePitcure = (id: string) => {
  const {t} = useTranslation('api');
  const {
    data,
    refetch: getProfilePicture,
    isLoading,
  } = useQuery({
    queryKey: ['userProfilePicture', id],
    queryFn: async () => {
      try {
        if (id) {
          const res = await fetch(`${apiUrl}/user/profile-image/${id}`, {
            headers: {
              Authorization: `${getFromStorage<string>(storage.TOKEN_TYPE)} ${getFromStorage<string>(storage.TOKEN)}`,
              Accept: '*/*',
            },
          });
          if (res) {
            const imageBlob = await res.blob();
            const url = URL.createObjectURL(imageBlob);
            return url;
          }
          return '';
        }
        return '';
      } catch (error) {
        console.log(error);
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    retry: 0,
  });

  return {
    data,
    isLoading,
    getProfilePicture,
  };
};

export const useAddProfilePicture = (pic: File, id: string) => {
  const {t} = useTranslation('api');
  const {
    mutate: addProfilePicture,
    data,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async () => {
      try {
        if (pic) {
          const formdata = new FormData();
          formdata.append('image', pic);
          await httpsProfilePicture.patch<SuccessResponse>(
            `/user/profile-image/${id}`,
            formdata,
            {
              headers: {
                Authorization: `${getFromStorage<string>(storage.TOKEN_TYPE)} ${getFromStorage<string>(storage.TOKEN)}`,
                Accept: '*/*',
                'Content-Type': 'multipart/form-data',
              },
            },
          );
          return t(getTranslationSuccessCode());
        }
        return Promise.reject(
          new Error(t(getTranslationErrorCode(), {ns: 'api'})),
        );
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    retry: 0,
  });

  return {
    addProfilePicture,
    data,
    isPending,
    isSuccess,
  };
};
