import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {isValidEmailFormat} from '../../../validation/appValidations';
import {User} from '../../../service/UserService/types';
import {useTranslation} from 'react-i18next';
import {useGetUserforAdminById} from '../../../service/UserService/useUserService';

interface Props {
  id: number;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleUpdate: (formData: User) => void;
}

const Index: React.FC<Props> = ({
  id,
  title,
  open,
  handleClose,
  handleUpdate,
}) => {
  const {t} = useTranslation();
  const {data, isLoading: isPendingData} = useGetUserforAdminById(id);

  const initData = {
    id: id,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    position: '',
    phone: '',
    description: '',
  };

  const isRequired = {required: {value: true}};

  const validations = {
    firstName: isRequired,
    lastName: isRequired,
    email: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => isValidEmailFormat(value),
          message: t('admDialogEmailValidation'),
        },
      ],
    },
  };

  const {formData, handleInputChange, formErrors, isValidForm, setFormData} =
    useForm<User>(initData, validations);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={!isValidForm || isPendingData}
      handleClose={handleClose}
      handleSecondButtonClick={() => handleUpdate(formData)}>
      <Box>
        <Input
          id="admin-first-name"
          name="firstName"
          label={t('admDialogFirstName')}
          initialValue={formData.firstName}
          onChange={handleInputChange}
          errorText={formErrors?.firstName || ''}
        />
        <Input
          id="admin-last-name"
          name="lastName"
          label={t('admDialogLastName')}
          initialValue={formData.lastName}
          onChange={handleInputChange}
          errorText={formErrors.lastName || ''}
        />
        <Input
          id="admin-username"
          name="username"
          label={t('admDialogUsername')}
          initialValue={formData?.username || ''}
          onChange={handleInputChange}
          disabled={true}
        />
        <Input
          id="admin-email"
          name="email"
          label={'E-mail'}
          initialValue={formData?.email || ''}
          onChange={handleInputChange}
          errorText={formErrors.email || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
