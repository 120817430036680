import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
  id: string;
  groupLabel: string;
  errorText: string;
  children: React.ReactNode;
}
const Index: React.FC<Props> = ({id, groupLabel, errorText, children}) => {
  const theme = useTheme();

  const errorTextCss = {
    padding: '7px',
  };

  return (
    <Box sx={{display: 'flex'}}>
      <FormControl
        id={id}
        error={errorText ? true : false}
        component="fieldset"
        sx={{m: 3}}
        variant="standard">
        <FormLabel component="legend">{groupLabel}</FormLabel>
        <FormGroup>{children}</FormGroup>
        {errorText && (
          <Typography sx={[errorTextCss, {color: theme.palette.error.light}]}>
            {errorText}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};

export default Index;
