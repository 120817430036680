import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Index: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '50px',
        paddingBottom: '50px',
      }}>
      <CircularProgress sx={{width: '100vw'}} />
    </Box>
  );
};

export default Index;
