import React, {createContext, useContext, useEffect, useState} from 'react';
import {
  getFromStorage,
  storage,
  setStorage,
  removeFromStorage,
} from '../../utils/storageUtils';
import {LoggedUser} from './types';
import {handleError, decode} from '../../utils/commonUtils';
import {useTranslation} from 'react-i18next';
import {LoginCredentials} from '../../service/AuthService/types';
import {AccessToken} from '../../service/types';
import {useAuth, useLogout} from '../../service/AuthService/useAuthService';
import {ROLES} from '../../types';
import {useGetUserById} from '../../service/UserService/useUserService';
import {User} from '../../service/UserService/types';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextValue {
  user: LoggedUser;
  userProfile: User;
  contextError: string;
  isCheckingCredetials: boolean;
  isAdmin: boolean;
  login: (credentials: LoginCredentials) => void;
  logout: () => void;
  loadUser: () => void;
}
export const AuthContext = createContext<AuthContextValue>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const [user, setUser] = useState(
    getFromStorage<LoggedUser>(storage.USER) ?? null,
  );
  const [credentials, setCredentials] = useState<LoginCredentials>();
  const {t} = useTranslation();
  const {logoutUser} = useLogout();
  const {checkAuth} = useAuth(credentials);
  const {data: userProfile, getUserProfile} = useGetUserById(false);
  const [contextError, setContextError] = useState('');
  const [isCheckingCredetials, setIsCheckingCredetials] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.id) {
      getUserProfile();
    }
  }, [user]);

  const login = (credentials: LoginCredentials) => {
    setCredentials(credentials);
  };

  const clear = () => {
    setUser(null);
    removeFromStorage(storage.USER);
    removeFromStorage(storage.TOKEN_TYPE);
    removeFromStorage(storage.TOKEN);
    removeFromStorage(storage.EXP_DATE);
  };

  const isValidToken = () => {
    const exp = getFromStorage<Date>(storage.EXP_DATE);
    return exp && new Date(exp) > new Date();
  };

  const handleTokenDetails = () => {
    const token = getFromStorage<string>(storage.TOKEN);
    let decodedToken: AccessToken = null;
    try {
      if (token) {
        decodedToken = decode(token, t('tokenCorrupted'));
        const {id, sub, roles, exp} = decodedToken;
        const user = {id: id, username: sub};
        setUser(user);
        setIsAdmin(roles.includes(ROLES.ADMIN));
        setStorage<LoggedUser>(storage.USER, user);
        setStorage<Date>(storage.EXP_DATE, new Date(exp * 1000));
      } else {
        clear();
      }
    } catch (error) {
      clear();
    }
  };

  useEffect(() => {
    const signin = async () => {
      setIsCheckingCredetials(true);
      try {
        const auth = await checkAuth();
        if (auth) {
          handleTokenDetails();
        }
      } catch (error) {
        setContextError(error?.message);
      } finally {
        setIsCheckingCredetials(false);
      }
    };

    if (credentials) {
      signin();
    }
  }, [credentials]);

  const loadUser = () => {
    handleTokenDetails();
    if (!isValidToken()) {
      clear();
    }
  };

  const logout = async () => {
    try {
      if (getFromStorage<string>(storage.TOKEN) && isValidToken()) {
        await logoutUser();
        clear();
      }
      clear();
    } catch (error) {
      //log error
      clear();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userProfile,
        contextError,
        isCheckingCredetials,
        isAdmin,
        login,
        logout,
        loadUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const {t} = useTranslation();
  const authContext = useContext(AuthContext);
  if (!authContext) {
    handleError('authContext', t('authContext'));
  }
  return authContext;
};
