import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Card} from '../../components';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {
  useGetAllClosedWOCountUser,
  useGetAllOpenedWOCount,
  useGetAllOpenedWOCountUser,
  useGetAllWOCount,
} from '../../service/DashboardService/useDashboardService';

const Index = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {userProfile, isAdmin} = useAuthContext();

  const {data: closed, getAllClosedWOCountUser} = useGetAllClosedWOCountUser(
    userProfile?.id,
  );
  const {data: opened, getAllOpenedWOCountUser} = useGetAllOpenedWOCountUser(
    userProfile?.id,
  );
  const {data: adminAll, getAllWOCount} = useGetAllWOCount();
  const {data: adminOpened, getAllOpenedWOCount} = useGetAllOpenedWOCount();

  useEffect(() => {
    if (isAdmin) {
      getAllWOCount();
      getAllOpenedWOCount();
    }
    if (!isAdmin && userProfile?.id) {
      getAllOpenedWOCountUser();
      getAllClosedWOCountUser();
    }
  }, [isAdmin, userProfile?.id]);

  return (
    <Grid
      container
      display={'flex'}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}>
      <Grid item>
        <Typography variant="h1" sx={textCenterCss}>
          {t('dashboard')}
        </Typography>
      </Grid>
      <Grid item sx={mainGridCss}>
        <Card id="dashboard-info-card">
          <Grid container display={'flex'} sx={containerCss('row')}>
            <Grid item sx={gridTextCss}>
              <Grid container sx={containerCss('column')}>
                <Grid
                  container
                  display={'flex'}
                  direction={'column'}
                  alignItems={'center'}>
                  <Typography variant="h2" sx={welcomeCss(theme)}>
                    {t('welcome')}
                  </Typography>
                  <Typography variant="h1" sx={nameCss(theme)}>
                    {`${userProfile?.firstName} ${userProfile?.lastName}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" sx={descriptionCss}>
                    {t('dashboardText')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={imageGridCss}>
              <div>
                <img
                  srcSet="/logo.jpg"
                  src="/logo.jpg"
                  alt="TWM logo"
                  loading="lazy"
                  width={'100%'}
                  style={{
                    alignSelf: 'center',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container sx={secondGridCss}>
        <Grid item>
          <Card id="dashboard-info-workorders-opened">
            <Typography variant="h3" sx={workordersCss}>
              {t('numberOfOpenedWO')}
            </Typography>
            <Grid container sx={gridOrderNumberCss}>
              <Grid item sx={width50Css}>
                <Typography variant="h1" sx={numberCss}>
                  {isAdmin ? adminOpened : opened}
                </Typography>
              </Grid>
              <Grid item sx={width50Css}>
                <AssignmentIcon sx={workorderIconCss} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item>
          <Card id="dashboard-info-workorders-all-or-closed">
            <Typography variant="h3" sx={workordersCss}>
              {isAdmin ? t('numberOfWO') : t('numberOfClosedWO')}
            </Typography>
            <Grid container sx={gridOrderNumberCss}>
              <Grid item sx={width50Css}>
                <Typography variant="h1" sx={numberCss}>
                  {isAdmin ? adminAll : closed}
                </Typography>
              </Grid>
              <Grid item sx={width50Css}>
                <AssignmentIcon sx={servicesIconCss} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const containerCss = (direction: string) => ({
  display: 'flex',
  direction: direction,
  alignItems: 'center',
  justifyContent: 'center',
});

const welcomeCss = theme => ({
  color: theme.palette.primary.main,
  paddingBottom: '20px',
  textAlign: 'center',
});

const gridTextCss = {
  width: {xs: '100%', sm: '60%'},
  paddingTop: '30px',
};

const nameCss = theme => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
});

const descriptionCss = {
  paddingLeft: '30px',
  justifyContent: 'center',
  textAlign: 'center',
  display: {xs: 'none', sm: 'none', md: 'flex'},
};

const imageGridCss = {
  width: '40%',
  display: {xs: 'none', sm: 'flex'},
  flexShrink: 1,
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const secondGridCss = {
  paddingTop: '20px',
  width: {xs: '95vw', md: '70vw'},
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '20px',
};

const mainGridCss = {width: {xs: '95vw', md: '70vw'}, alignItems: 'center'};

const servicesIconCss = {width: '55px', height: '55px', color: 'purple'};

const workorderIconCss = {width: '55px', height: '55px', color: 'orange'};

const textCenterCss = {textAlign: 'center'};

const numberCss = {textAlign: 'right', paddingRight: '15px'};

const workordersCss = {textAlign: 'center', paddingBottom: '20px'};

const gridOrderNumberCss = {display: 'flex', direction: 'row', width: '100%'};

const width50Css = {width: '50%'};

export default Index;
