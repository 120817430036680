import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog, PhoneInput} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {
  isValidEmailFormat,
  isPhoneLengthValid,
  isValidLength,
} from '../../../validation/appValidations';
import {useTranslation} from 'react-i18next';
import {Client} from '../../../service/ClientService/types';

interface Props {
  id?: string;
  data?: Client;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (formData: Client) => void;
}

const Index: React.FC<Props> = ({
  data,
  title,
  open,
  id = 'client-modal',
  handleSubmit,
  handleClose,
}) => {
  const {t} = useTranslation();

  const initData = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  const isRequired = {required: {value: true}};

  const validations = {
    name: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => isValidLength(value, 1, 100),
          message: t('admDialogNameValidation'),
        },
      ],
    },
    firstName: isRequired,
    lastName: isRequired,
    email: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => isValidEmailFormat(value),
          message: t('admDialogEmailValidation'),
        },
      ],
    },
    phone: {
      custom: [
        {
          isValid: (phone: string) => isPhoneLengthValid(phone),
          message: t('phoneLengthValidation'),
        },
      ],
    },
  };

  const {formData, handleInputChange, formErrors, isValidForm, setFormData} =
    useForm<Client>(initData, validations);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  return (
    <Dialog
      id={id}
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={!isValidForm}
      handleClose={handleClose}
      secondButtonTitle={!data ? t('createBtn') : t('updateBtn')}
      handleSecondButtonClick={() => handleSubmit(formData)}>
      <Box>
        <Input
          id="client-modal-name"
          name="name"
          label={t('clientModalName')}
          initialValue={formData.name}
          onChange={handleInputChange}
          errorText={formErrors.name || ''}
        />
        <Input
          id="client-modal-first-name"
          name="firstName"
          label={t('clientModalFirstName')}
          initialValue={formData.firstName}
          onChange={handleInputChange}
          errorText={formErrors?.firstName || ''}
        />
        <Input
          id="client-modal-last-name"
          name="lastName"
          label={t('clientModalLastName')}
          initialValue={formData.lastName}
          onChange={handleInputChange}
          errorText={formErrors.lastName || ''}
        />
        <Input
          id="client-modal-email"
          name="email"
          label={'E-mail'}
          initialValue={formData?.email || ''}
          onChange={handleInputChange}
          errorText={formErrors.email || ''}
        />
        <PhoneInput
          id="client-modal-phone"
          name="phone"
          label={t('clientModalPhone')}
          initialValue={formData.phone}
          onChange={handleInputChange}
          errorText={formErrors?.phone || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
