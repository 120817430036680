import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {SXProps} from '../types';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  showTooltip?: boolean;
  firstText?: string;
  secondText?: string;
  isReplaceColors?: boolean;
  isFirstTextBold?: boolean;
  isSecondTextBold?: boolean;
  sx?: SXProps;
}

const Index: React.FC<Props> = ({
  sx,
  firstText = '',
  secondText = '',
  showTooltip = false,
  isReplaceColors = false,
  isFirstTextBold = false,
  isSecondTextBold = false,
}) => {
  const getDefaultFirstTextSxProps = () => {
    if (isFirstTextBold) {
      return {...defaultText, ...boldTextCss};
    }
    return defaultText;
  };

  const getDefaultSecondTextSxProps = () => {
    if (isSecondTextBold) {
      return {...greyText, ...boldTextCss};
    }
    return greyText;
  };

  return (
    <Grid container display={'flex'} direction={'column'} sx={sx?.main}>
      <Grid item>
        {showTooltip ? (
          <Tooltip title={firstText}>
            <Typography
              sx={
                sx?.firstText
                  ? sx?.firstText
                  : isReplaceColors
                    ? getDefaultSecondTextSxProps()
                    : getDefaultFirstTextSxProps()
              }>
              {firstText}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={
              sx?.firstText
                ? sx?.firstText
                : isReplaceColors
                  ? getDefaultSecondTextSxProps()
                  : getDefaultFirstTextSxProps()
            }>
            {firstText}
          </Typography>
        )}
      </Grid>
      <Grid item>
        {showTooltip ? (
          <Tooltip title={secondText}>
            <Typography
              sx={
                sx?.secondText
                  ? sx?.secondText
                  : isReplaceColors
                    ? getDefaultFirstTextSxProps()
                    : getDefaultSecondTextSxProps()
              }>
              {secondText}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={
              sx?.secondText
                ? sx?.secondText
                : isReplaceColors
                  ? getDefaultFirstTextSxProps()
                  : getDefaultSecondTextSxProps()
            }>
            {secondText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const boldTextCss = {
  fontWeight: 600,
};
const defaultText = {
  color: '#000',
  fontSize: '1em',
  paddingBottom: '5px',
};

const greyText = {color: 'gray', fontSize: '1em'};
export default Index;
