import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {
  DataGrid,
  GridRowSpacingParams,
  GridColDef,
  GridEventListener,
  GridRowParams,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {PaginationModel} from '../../../service/types';
import {styled} from '@mui/material/styles';
import {TableData} from '../../../service/types';
import useMediaQuery from '@mui/material/useMediaQuery';
import {TableAction} from './types';
import usePrevious from '../../../hooks/usePrevious/usePrevious';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@tanstack/react-query';
import {Status} from '../../../service/types';
import EmptyRows from './EmptyRows';

const BootstrapDataGrid = styled(DataGrid)(props => ({
  '& .MuiDataGrid-row': {
    borderRadius: '5px',
    backgroundColor: '#fff',
    transform: 'scale(1)',
    boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
    width: '96%',
    marginLeft: '5px',
    marginTop: '5px',
    marginBottom: '1px',
  },
  '&, [class^=MuiDataGrid]': {
    border: 'none',
    marginBottom: '1px',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
    display: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  fontSize: '1em',
  [props.theme.breakpoints.up('sm')]: {
    '&, [class^=MuiDataGrid]': {
      overflow: props?.rows?.length > 0 ? 'hidden' : 'visible',
    },
  },
}));

interface Props {
  queryFn: (...props: any) => Promise<TableData>;
  columns: GridColDef[];
  searchCriteria?: string;
  isSearch?: boolean;
  setIsSearch?: Dispatch<SetStateAction<boolean>>;
  tableDeleteRow?: TableAction;
  tableUpdateRow?: TableAction;
  tableCreateRow?: TableAction;
  onRowClick?: (params: GridRowParams) => void;
}

const Index: React.FC<Props> = ({
  queryFn,
  columns,
  searchCriteria,
  isSearch,
  setIsSearch,
  tableDeleteRow,
  tableUpdateRow,
  tableCreateRow,
  onRowClick,
}) => {
  const {t} = useTranslation('api');
  const smallDevices = useMediaQuery('(max-width:650px)');
  const [sortingModel, setSortingModel] = React.useState({});
  const [filterModel, setFilterModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>(
    {
      page: 0,
      pageSize: 20,
    },
  );
  const prevPaginationModel = usePrevious(paginationModel);

  const {
    refetch: getData,
    data,
    isLoading,
  } = useQuery({
    queryKey: [queryFn.name, paginationModel],
    queryFn: async () => queryFn(t, paginationModel, searchCriteria),
    retry: 0,
    enabled: false,
  });

  const [tableData, setTableData] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(data?.totalElements || 0);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isSearch) {
      getData();
      setIsSearch(false);
    }
  }, [isSearch]);

  useEffect(() => {
    setTotalRows(prevTotalRows =>
      data?.totalElements != undefined ? data?.totalElements : prevTotalRows,
    );
  }, [data?.totalElements]);

  useEffect(() => {
    setTableData(prevData =>
      data?.content != undefined ? data?.content : prevData,
    );
  }, [data?.content]);

  useEffect(() => {
    if (tableDeleteRow?.status && tableDeleteRow?.status === Status.SUCCESS) {
      if (paginationModel.page > 0 && tableData?.length === 1) {
        setPaginationModel({
          ...paginationModel,
          page: paginationModel.page - 1,
        });
      } else {
        getData();
      }
      if (tableDeleteRow?.afterSuccessCallback) {
        tableDeleteRow?.afterSuccessCallback();
      }
    }
  }, [tableDeleteRow?.status]);

  useEffect(() => {
    if (paginationModel.page !== prevPaginationModel?.page) {
      getData();
    }
  }, [paginationModel]);

  useEffect(() => {
    if (tableUpdateRow?.status && tableUpdateRow?.status === Status.SUCCESS) {
      getData();
      if (tableUpdateRow?.afterSuccessCallback) {
        tableUpdateRow?.afterSuccessCallback();
      }
    }
  }, [tableUpdateRow?.status]);

  useEffect(() => {
    if (tableCreateRow?.status && tableCreateRow?.status === Status.SUCCESS) {
      getData();
      if (tableCreateRow?.afterSuccessCallback) {
        tableCreateRow?.afterSuccessCallback();
      }
    }
  }, [tableCreateRow?.status]);

  const handleEvent: GridEventListener<'rowClick'> = params => {
    if (onRowClick) {
      onRowClick(params);
    }
  };

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 10,
      bottom: params.isLastVisible ? 0 : 10,
    };
  }, []);

  return (
    <Box sx={{width: '95%'}}>
      <BootstrapDataGrid
        columns={columns}
        rows={tableData || []}
        loading={isLoading}
        rowHeight={smallDevices ? 50 : 100}
        onRowClick={handleEvent}
        getRowSpacing={getRowSpacing}
        rowCount={totalRows}
        pageSizeOptions={[5]}
        slots={{
          noRowsOverlay: EmptyRows,
        }}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        onSortModelChange={setSortingModel}
        filterMode="server"
        onFilterModelChange={setFilterModel}
        rowSelection={false}
        autoHeight
      />
    </Box>
  );
};

export default React.memo(Index);
