import React from 'react';
import Button from '@mui/material/Button';
import {SxProps} from '@mui/material';
import {buttonType} from './types';

interface Props {
  id: string;
  text?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  sx?: SxProps;
  type?: buttonType;
  onClick?: () => void;
}

const Index: React.FC<Props> = ({
  id,
  text,
  startIcon,
  endIcon,
  disabled,
  autoFocus,
  sx,
  type = buttonType.primary,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Button
      id={id}
      color={type}
      sx={{...buttonCss, ...sx}}
      variant="contained"
      disabled={disabled}
      autoFocus={autoFocus}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={handleClick}>
      {text}
    </Button>
  );
};

const buttonCss = {
  borderRadius: '15px',
  paddingTop: '15px',
  paddingBottom: '15px',
  paddingLeft: '20px',
  paddingRight: '20px',
  margin: '7px',
};

export default Index;
