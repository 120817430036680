import {jwtDecode} from 'jwt-decode';
import {AccessToken} from '../service/types';

export const handleError = (name: string, message: string) => {
  // logError
  throw {error: name, message: message};
};

export const decode = (token: string, errorMessage): AccessToken | null => {
  if (token) {
    try {
      return jwtDecode(token);
    } catch {
      return handleError('decodeToken', errorMessage);
    }
  }
  return handleError('decodeToken', errorMessage);
};

export const formatCurrency = (num?: number | string) => {
  return num.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
};
