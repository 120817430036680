import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog, DatePicker, TextArea} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {WorkOrder} from '../../../service/WorkOrderService/types';
import {Select} from '../..';
import {useGetUserList} from '../../../service/UserService/useUserService';
import {useGetClientListModal} from '../../../service/ClientService/useClientService';
import {useGetDeviceList} from '../../../service/DevicesService/useDeviceService';
import {SelectItem} from '../../common/Select/types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Device} from '../../../service/DevicesService/types';
import {User} from '../../../service/UserService/types';
import {Client} from '../../../service/ClientService/types';
import {useAuthContext} from '../../../contexts/useAuthContext/useAuthContext';

interface Props {
  id: string;
  data?: WorkOrder;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (formData: WorkOrder) => void;
}

const Index: React.FC<Props> = ({
  id,
  data,
  title,
  open,
  handleSubmit,
  handleClose,
}) => {
  const {t} = useTranslation();
  const {isAdmin, user} = useAuthContext();

  const initData = {
    workOrderNumber: '',
    warranty: false,
    workDone: false,
    qualityChecked: false,
    sparePartsSaved: false,
    openedDate: null,
    closedDate: null,
    faultDescription: '',
    comment: '',
    deviceTakenOver: null,
    client: {label: '', value: ''},
    device: {label: '', value: ''},
    user: {label: '', value: ''},
  };

  const isRequired = {required: {value: true}};

  const validations = {
    workOrderNumber: {
      required: {value: true},
      custom: [
        {
          isValid: (value: string) => value.length <= 10,
          message: t('WORNLengthValidation'),
        },
      ],
    },
    openedDate: {
      required: {value: true},
      custom: [
        {
          isValid: (date: string, compareDate: string, compareDate2: string) =>
            ((!compareDate && date != '') ||
              !date ||
              (compareDate != '' && date <= compareDate)) &&
            ((!compareDate2 && date != '') ||
              !date ||
              (compareDate2 != '' && date <= compareDate2)),
          message: t('WOOpenedDateValidation'),
        },
      ],
    },
    closedDate: {
      custom: [
        {
          isValid: (date: string, compareDate: string, compareDate2: string) =>
            ((!compareDate && date != '') ||
              !date ||
              (compareDate != '' && date >= compareDate)) &&
            ((!compareDate2 && date != '') ||
              !date ||
              (compareDate2 != '' && date >= compareDate2)),
          message: t('WOClosedDateValidation'),
        },
      ],
    },
    deviceTakenOver: {
      custom: [
        {
          isValid: (date: string, compareDate: string, compareDate2: string) =>
            ((!compareDate && date != '') ||
              !date ||
              (compareDate != '' && date >= compareDate)) &&
            ((!compareDate2 && date != '') ||
              !date ||
              (compareDate2 != '' && date <= compareDate2)),
          message: t('WODeviceTakenOverValidation'),
        },
      ],
    },
    client: isRequired,
    device: isRequired,
    user: isRequired,
  };

  const {
    formData,
    handleInputChange,
    handleSelectChange,
    handleDatePickerChange,
    handleTextAreaChange,
    handleCheckboxChange,
    formErrors,
    isValidForm,
    setFormData,
  } = useForm<WorkOrder>(initData, validations);

  const {data: userList} = useGetUserList();
  const {data: clientList} = useGetClientListModal();
  const {data: deviceList} = useGetDeviceList(
    (formData?.client as SelectItem)?.value,
  );

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        client: {
          value: (data?.client as Client)?.id.toString(),
          name: (data?.client as Client)?.name,
        },
        device: {
          value: (data?.device as Device)?.id.toString(),
          name: (data?.device as Device)?.name,
        },
        user: {
          value: (data?.user as User)?.id.toString(),
          name: `${(data?.user as User)?.firstName} ${(data?.user as User)?.lastName}`,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    const loggedUser = userList?.find((u: SelectItem) => u?.value == user?.id);
    if (!isAdmin && loggedUser && !data) {
      setFormData({...formData, user: loggedUser});
    }
  }, [isAdmin, userList, data, user?.id]);

  const onSubmit = (data: WorkOrder) => {
    const parseData = {
      ...data,
      client: {id: (data?.client as SelectItem)?.value},
      device: {id: (data?.device as SelectItem)?.value},
      user: {id: (data?.user as SelectItem)?.value},
    };
    handleSubmit(parseData);
  };

  return (
    <Dialog
      id={id}
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={!isValidForm}
      handleClose={handleClose}
      secondButtonTitle={!data ? t('createBtn') : t('updateBtn')}
      handleSecondButtonClick={() => onSubmit(formData)}>
      <Box>
        <Input
          id="work-order-modal-workOrderNumber"
          name="workOrderNumber"
          label={t('WONumber')}
          initialValue={formData.workOrderNumber}
          onChange={handleInputChange}
          errorText={formErrors.workOrderNumber || ''}
          disabled={data?.workOrderNumber ? true : false}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData?.warranty}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => handleCheckboxChange('warranty', checked)}
            />
          }
          sx={{paddingLeft: 3}}
          label={t('WOWarrantyModal')}
        />
        <DatePicker
          id="work-order-modal-openedDate"
          name="openedDate"
          compareDate="closedDate"
          compareDate2="deviceTakenOver"
          label={t('WOOpenedDate')}
          initialValue={formData.openedDate}
          onChange={handleDatePickerChange}
          errorText={formErrors.openedDate || ''}
        />
        <DatePicker
          id="work-order-modal-closedDate"
          name="closedDate"
          compareDate="openedDate"
          compareDate2="deviceTakenOver"
          label={t('WOClosedDate')}
          initialValue={formData.closedDate}
          onChange={handleDatePickerChange}
          errorText={formErrors.closedDate || ''}
        />
        <DatePicker
          id="work-order-modal-deviceTakenOver"
          name="deviceTakenOver"
          compareDate="openedDate"
          compareDate2="closedDate"
          label={t('WODeviceTakenOver')}
          initialValue={formData.deviceTakenOver}
          onChange={handleDatePickerChange}
          errorText={formErrors.deviceTakenOver || ''}
        />
        <Select
          id="work-order-modal-client"
          name="client"
          items={clientList}
          label={t('WOClientName')}
          value={formData?.client as SelectItem}
          onChange={handleSelectChange}
          errorText={formErrors?.client || ''}
        />
        <Select
          id="work-order-modal-device"
          name="device"
          items={deviceList}
          label={t('WODeviceName')}
          value={formData?.device as SelectItem}
          onChange={handleSelectChange}
          errorText={formErrors?.device || ''}
        />
        <Select
          id="work-order-modal.user"
          name="user"
          items={userList}
          label={t('WOAssignedTo')}
          value={formData?.user as SelectItem}
          onChange={handleSelectChange}
          disabled={!isAdmin}
          errorText={formErrors?.user || ''}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData?.workDone}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => handleCheckboxChange('workDone', checked)}
            />
          }
          sx={{paddingLeft: 3}}
          label={t('WOWorkDoneModal')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData?.qualityChecked}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => handleCheckboxChange('qualityChecked', checked)}
            />
          }
          sx={{paddingLeft: 3}}
          label={t('WOQualityCheckedModal')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData?.sparePartsSaved}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => handleCheckboxChange('sparePartsSaved', checked)}
            />
          }
          sx={{paddingLeft: 3}}
          label={t('WOSparePartsSavedModal')}
        />
        <TextArea
          id="device-modal-faultDescription"
          name="faultDescription"
          label={t('WOFaultDescription')}
          initialValue={formData?.faultDescription || ''}
          onChange={handleTextAreaChange}
          errorText={formErrors?.faultDescription || ''}
        />
        <TextArea
          id="device-modal-comment"
          name="comment"
          label={t('WOComment')}
          initialValue={formData?.comment || ''}
          onChange={handleTextAreaChange}
          errorText={formErrors?.comment || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
