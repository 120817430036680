import * as React from 'react';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/material/styles';
import {useTheme, Breakpoint} from '@mui/material';
import {Button, buttonType} from '../..';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type SXProps = {
  dialog?: SxProps;
  title?: SxProps;
  content?: SxProps;
  actions?: SxProps;
  firstButton?: SxProps;
  secondButton?: SxProps;
};

interface Props {
  id?: string;
  open: boolean;
  title: string;
  fullWidth?: boolean;
  fullScreen?: boolean;
  fullWidthSx?: false | Breakpoint;
  firstButtonTitle?: string;
  secondButtonTitle?: string;
  secondButtonDisabled?: boolean;
  secondButtonType?: buttonType;
  hideFirstButtonButton?: boolean;
  hideSecondButtonButton?: boolean;
  children: React.ReactNode;
  sx?: SXProps;
  handleClose: (data?: unknown) => void;
  handleSecondButtonClick?: () => void;
}

const Index: React.FC<Props> = ({
  id,
  open,
  title,
  sx,
  children,
  firstButtonTitle,
  secondButtonTitle,
  secondButtonDisabled,
  hideFirstButtonButton,
  hideSecondButtonButton,
  secondButtonType,
  fullWidth = false,
  fullWidthSx = 'sm',
  fullScreen = false,
  handleClose,
  handleSecondButtonClick,
}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const closeButtonCss = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        id={id}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        onClose={handleClose}
        sx={sx?.dialog}
        maxWidth={fullWidthSx}
        PaperProps={{sx: {borderRadius: '20px'}}}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle
          sx={{...titleCss, ...sx?.title}}
          id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={closeButtonCss}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={sx?.content}>{children}</DialogContent>
        <DialogActions sx={sx?.actions}>
          {!hideFirstButtonButton && (
            <Button
              id="modal-close"
              onClick={handleClose}
              sx={sx?.firstButton}
              text={firstButtonTitle ? firstButtonTitle : t('dialogDiscard')}
            />
          )}
          {!hideSecondButtonButton && (
            <Button
              autoFocus={true}
              id="modal-save"
              sx={sx?.secondButton}
              type={secondButtonType}
              onClick={handleSecondButtonClick}
              disabled={secondButtonDisabled}
              text={secondButtonTitle ? secondButtonTitle : t('dialogSave')}
            />
          )}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

const titleCss = {
  m: 0,
  p: 2,
  paddingLeft: '40px',
  paddingTop: '40px',
  fontSize: '1.5em',
  fontWeight: 'bold',
};
export default Index;
