import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog, TextArea} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {ClientDetails} from '../../../service/ClientService/types';

interface Props {
  data?: ClientDetails;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (formData: ClientDetails) => void;
}

const Index: React.FC<Props> = ({
  data,
  title,
  open,
  handleSubmit,
  handleClose,
}) => {
  const {t} = useTranslation();

  const initData = {
    name: '',
    value: '',
    description: '',
  };

  const validationInput = (isRequired: boolean) => ({
    required: {value: isRequired},
    custom: [
      {
        isValid: (value: string) => value.length <= 50,
        message: t('deviceDetailsModalLengthValidation50'),
      },
    ],
  });

  const validationText = () => ({
    custom: [
      {
        isValid: (value: string) => value.length <= 500,
        message: t('deviceDetailsModalLengthValidation500'),
      },
    ],
  });

  const validations = {
    name: validationInput(true),
    value: validationInput(true),
    description: validationText(),
  };

  const {formData, handleInputChange, formErrors, isValidForm, setFormData} =
    useForm<ClientDetails>(initData, validations);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={!isValidForm}
      handleClose={handleClose}
      secondButtonTitle={!data ? t('createBtn') : t('updateBtn')}
      handleSecondButtonClick={() => handleSubmit(formData)}>
      <Box>
        <Input
          id="client-details-name"
          name="name"
          label={t('clientDetailsModalName')}
          initialValue={formData.name}
          onChange={handleInputChange}
          errorText={formErrors.name || ''}
        />
        <Input
          id="client-details-value"
          name="value"
          label={t('clientDetailsModalValue')}
          initialValue={formData.value}
          onChange={handleInputChange}
          errorText={formErrors?.value || ''}
        />
        <TextArea
          id="client-details-description"
          name="description"
          rows={10}
          label={t('clientDetailsModalDescription')}
          initialValue={formData.description}
          onChange={handleInputChange}
          errorText={formErrors.description || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
