import {SxProps} from '@mui/material';

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
}

export type SXProps = {
  box?: SxProps;
  input?: SxProps;
};
