import React, {useState, useEffect} from 'react';
import Box from '@mui/system/Box';
import {
  Input,
  FileUpload,
  Suspense,
  TextArea,
  UserProfileModal,
  Button,
  ChangePasswordModal,
} from '../../components';
import {useTranslation} from 'react-i18next';
import {
  useGetUserById,
  useGetUserProfilePitcure,
  useAddProfilePicture,
} from '../../service/UserService/useUserService';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import Badge from '@mui/material/Badge';
import {useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {useChangePassword} from '../../service/PasswordService/usePasswordService';

const Index: React.FC = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {user} = useAuthContext();
  const {id: currentUserId} = user;
  const {data, isLoading: isPendingData, getUserProfile} = useGetUserById();
  const {data: pictureUrl, getProfilePicture} =
    useGetUserProfilePitcure(currentUserId);
  const [openEditPictureModal, setOpenEditPictureModal] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [file, setFile] = useState<File>(null);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const {
    isPending: isPendingUpload,
    addProfilePicture,
    isSuccess,
  } = useAddProfilePicture(file, currentUserId);

  const {isPasswordChanged, changeCurrentPassword} = useChangePassword();

  useEffect(() => {
    if (file && file.type.match('image.*')) {
      addProfilePicture();
    }
  }, [file]);

  useEffect(() => {
    if (isSuccess) {
      setOpenEditPictureModal(false);
      getProfilePicture();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPasswordChanged) {
      setOpenChangePasswordModal(false);
    }
  }, [isPasswordChanged]);

  const editButtonCss = {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    marginTop: '10px',
    width: 50,
    height: 50,
    borderRadius: '10px',
  };

  const editPictureIconCss = {
    color: theme.palette.primary.main,
    paddingTop: '5px',
    width: 40,
    height: 40,
  };

  const onCloseEditModal = (refetch: boolean) => {
    setOpenEditProfileModal(false);
    if (refetch) {
      getUserProfile();
    }
  };

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid
        item
        sx={{
          alignSelf: 'center',
        }}>
        <Typography variant="h1">{t('myProfileTitle')}</Typography>
      </Grid>
      <Grid
        item
        display={'flex'}
        sx={mainGridCss}
        component={Paper}
        elevation={6}>
        <Grid container display={'flex'} direction={'column'}>
          <Grid item>
            <Box sx={profilePicBoxCss}>
              <Grid container width={'100%'} direction="row">
                <Grid item>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    badgeContent={
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setOpenEditPictureModal(true)}>
                        <AddAPhotoIcon sx={editPictureIconCss} />
                      </IconButton>
                    }>
                    <Avatar
                      alt="Profile picture"
                      srcSet={pictureUrl}
                      sx={profilePicCss}
                    />
                  </Badge>
                </Grid>
                <Grid item>
                  <Button
                    id="change-password-button"
                    text={t('changePasswordbtn')}
                    onClick={() => setOpenChangePasswordModal(true)}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="edit profile"
                    component="button"
                    sx={editButtonCss}
                    onClick={() => setOpenEditProfileModal(true)}>
                    <EditOutlinedIcon sx={editButtonIconCss} />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography variant="h2" sx={nameCss}>
                {`${data?.firstName || ''} ${data?.lastName || ''}`}
              </Typography>
            </Box>
          </Grid>
          <Suspense isLoading={isPendingData}>
            <Grid item>
              <Typography variant="h3" sx={sectionCss}>
                {t('myProfileMainInfo')}
              </Typography>
              <Input
                id="profile-first-name"
                name="firstName"
                label={t('myProfileFirstName')}
                initialValue={data?.firstName || ''}
                disabled={true}
              />
              <Input
                id="profile-last-name"
                name="lastName"
                label={t('myProfileLastName')}
                initialValue={data?.lastName || ''}
                disabled={true}
              />
              <Input
                id="profile-position"
                name="position"
                label={t('myProfilePosition')}
                initialValue={data?.position || ''}
                disabled={true}
              />
              <TextArea
                id="profile-description"
                name="description"
                label={t('myProfileDescription')}
                initialValue={data?.description || ''}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <Typography variant="h3" sx={sectionCss}>
                {t('myProfileContactInfo')}
              </Typography>
              <Input
                id="profile-email"
                name="email"
                label={'E-mail'}
                initialValue={data?.email || ''}
                disabled={true}
              />
              <Input
                id="profile-phone"
                name="phone"
                label={t('myProfilePhone')}
                initialValue={data?.phone || ''}
                disabled={true}
              />
              <Input
                id="profile-username"
                name="username"
                label={t('myProfileUsername')}
                initialValue={data?.username || ''}
                disabled={true}
              />
            </Grid>
          </Suspense>
          {openEditPictureModal && (
            <FileUpload
              open={openEditPictureModal}
              title={t('imageFileUploadTitle')}
              imageFileTypeOnly={true}
              handleClose={() => setOpenEditPictureModal(false)}
              isButtonDisabled={isPendingUpload}
              handleUpload={(file: File) => setFile(file)}
            />
          )}
          {openEditProfileModal && (
            <UserProfileModal
              open={openEditProfileModal ? true : false}
              title={t('editProfileTitle')}
              handleClose={onCloseEditModal}
            />
          )}
          {openChangePasswordModal && (
            <ChangePasswordModal
              open={openChangePasswordModal}
              handleClose={() => setOpenChangePasswordModal(false)}
              handleSubmit={data => changeCurrentPassword(data)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const editButtonIconCss = {
  color: '#000',
  width: 40,
  height: 40,
};

const nameCss = {
  color: '#000',
  paddingTop: '30px',
  paddingBottom: '10px',
};

const sectionCss = {
  color: '#000',
  paddingTop: '20px',
  paddingBottom: '10px',
};

const mainGridCss = {
  flexGrow: 1,
  backgroundColor: '#fff',
  width: {xs: '95vw', md: '70vw'},
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '30px',
  padding: {xs: '1em', md: '3em'},
  alignSelf: 'center',
};

const profilePicBoxCss = {
  width: {xs: '50vw', md: '30vw'},
  borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
  paddingBottom: '10px',
};

const profilePicCss = {
  width: {xs: 100, md: 200},
  height: {xs: 100, md: 200},
};
export default Index;
