import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  httpsAuthorized,
  getTranslationErrorCode,
} from '../common';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {Device, DeviceDetails} from './types';
import {PaginationModel, TableData, SearchCriteria} from '../types';
import {TFunction} from 'i18next';
import {SelectItem} from '../../components/common/Select/types';
import {useNavigate} from 'react-router-dom';

export const getDevices = async (
  t: TFunction,
  paginationModel: PaginationModel,
  searchText: string,
) => {
  const queryParams = `?page=${paginationModel.page + 1}&size=${paginationModel.pageSize}`;
  const result = await httpPost<SearchCriteria>(
    `device/all${queryParams}`,
    {
      key: 'name',
      operation: 'BEGINS',
      value: searchText,
    },
    t,
    false,
  );
  return result as TableData;
};

export const useGetDeviceList = (clientId: string) => {
  const {t} = useTranslation('api');
  const {
    refetch: getDeviceList,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['ClientList', clientId],
    queryFn: async () => {
      if (clientId) {
        try {
          const {data} = await httpsAuthorized.get<Device[]>(
            `device/all/${clientId}`,
          );
          const selectValues: SelectItem[] = [];
          data?.forEach((device: Device) => {
            selectValues.push({
              label: device.name,
              value: device.id.toString(),
            });
          });
          return selectValues;
        } catch (error) {
          return Promise.reject(
            new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
          );
        }
      }
      return [];
    },
    retry: 0,
  });

  return {
    getDeviceList,
    data,
    isLoading,
  };
};

export const useGetDeviceById = (id: string, enabled = true) => {
  const {t} = useTranslation('api');
  const {
    refetch: getDeviceById,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['DeviceById', id],
    queryFn: async () => {
      return await httpGet<Device>(`device/${id}`, t);
    },
    retry: 0,
    enabled: enabled,
  });

  return {
    getDeviceById,
    data,
    isLoading,
  };
};

export const useUpdateDevice = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateDeviceById,
    data,
    isPending,
    isSuccess: isSuccessUpdateDevice,
    status: updateStatus,
  } = useMutation({
    mutationFn: async (value: Device) => {
      return await httpPut<Device>(`device/${id}`, value, t);
    },
    retry: 0,
  });

  return {
    updateDeviceById,
    data,
    isPending,
    isSuccessUpdateDevice,
    updateStatus,
  };
};

export const useUpdateDeviceDetails = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateDeviceDetails,
    data,
    isPending,
    isSuccess: isSuccessUpdate,
  } = useMutation({
    mutationFn: async (value: DeviceDetails) => {
      return await httpPut<DeviceDetails>(
        `device/device-details/${id}`,
        value,
        t,
      );
    },
    retry: 0,
  });

  return {
    updateDeviceDetails,
    data,
    isPending,
    isSuccessUpdate,
  };
};

export const useDeleteDeviceById = (id: number) => {
  const {t} = useTranslation('api');
  const {mutate: deleteDeviceById, status: deleteStatus} = useMutation({
    mutationFn: async () => {
      return await httpDelete(`device/${id}`, t);
    },
    retry: 0,
  });

  return {
    deleteDeviceById,
    deleteStatus,
  };
};

export const useDeleteDeviceDetails = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: deleteDeviceDetails,
    isPending,
    isSuccess: isSuccessDelete,
  } = useMutation({
    mutationFn: async () => {
      return await httpDelete(`device/device-details/${id}`, t);
    },
    retry: 0,
  });

  return {
    deleteDeviceDetails,
    isPending,
    isSuccessDelete,
  };
};

export const useCreateDevice = () => {
  const {t} = useTranslation('api');
  const navigate = useNavigate();
  const {
    mutate: createDevice,
    data,
    status: createStatus,
  } = useMutation({
    mutationFn: async (value: Device) => {
      return await httpPost<Device>('device', value, t, false);
    },
    onSuccess: (data: Device) => {
      if (data.id) {
        navigate(`/devices/${data.id}`);
      }
    },
    retry: 0,
  });
  return {
    createDevice,
    data,
    createStatus,
  };
};

export const useCreateDeviceDetails = (deviceId: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: createDeviceDetails,
    data,
    isPending,
    isSuccess: isSuccessCreate,
  } = useMutation({
    mutationFn: async (value: DeviceDetails) => {
      return await httpPost<DeviceDetails>(
        `device/device-details/${deviceId}`,
        value,
        t,
      );
    },
    retry: 0,
  });

  return {
    createDeviceDetails,
    data,
    isPending,
    isSuccessCreate,
  };
};
