import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {WorkOrder} from '../../../service/WorkOrderService/types';
import {WorkOrderItem} from '../../../service/WorkOrderItemsService/types';
import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {DeleteModal, WorkOrderItemModal} from '../..';
import {
  useDeleteWorkOrderItem,
  useUpdateWorkOrderItem,
} from '../../../service/WorkOrderItemsService/useWorkOrderItemsService';
import {Status} from '../../../service/types';
import {RefetchOptions, QueryObserverResult} from '@tanstack/react-query';

const BootstrapDataGrid = styled(DataGrid)({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
});

interface Props {
  data: WorkOrderItem[];
  areActionButtonsDisabled: boolean;
  getWO: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<WorkOrder, Error>>;
}

const Index: React.FC<Props> = ({data, areActionButtonsDisabled, getWO}) => {
  const {t} = useTranslation();

  const [selectedItem, setSelectedItem] = useState<WorkOrderItem | undefined>(
    undefined,
  );
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | undefined>(
    undefined,
  );

  //actions
  const {updateStatus, updateWorkOrderItem} = useUpdateWorkOrderItem(
    selectedItem?.id,
  );

  const {deleteWorkOrderItem, deleteStatus} =
    useDeleteWorkOrderItem(selectedDeleteId);

  useEffect(() => {
    if (deleteStatus === Status.SUCCESS) {
      getWO();
      setSelectedDeleteId(undefined);
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (updateStatus === Status.SUCCESS) {
      getWO();
      setSelectedItem(undefined);
    }
  }, [updateStatus]);

  const columns: GridColDef[] = [
    {
      field: 'rbr',
      headerName: t('WOItemsTableRbr'),
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      maxWidth: 50,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'code',
      headerName: t('WOItemsTableCode'),
      flex: 1,
      align: 'center',
      maxWidth: 100,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'name',
      headerName: t('WOItemsTableName'),
      flex: 1,
      align: 'left',
      minWidth: 180,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'unit',
      headerName: t('WOItemsTableUnit'),
      flex: 1,
      align: 'center',
      maxWidth: 100,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'quantity',
      headerName: t('WOItemsTableQuantity'),
      flex: 1,
      align: 'center',
      maxWidth: 100,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton
            onClick={() => setSelectedItem(params?.row)}
            disabled={areActionButtonsDisabled ? true : false}>
            <EditIcon
              color={areActionButtonsDisabled ? 'disabled' : 'primary'}
            />
          </IconButton>
          <IconButton
            onClick={() => setSelectedDeleteId(params?.row?.id)}
            disabled={areActionButtonsDisabled ? true : false}>
            <DeleteIcon
              color={areActionButtonsDisabled ? 'disabled' : 'primary'}
            />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <BootstrapDataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize: 5},
          },
        }}
        pageSizeOptions={[5]}
        rowSelection={false}
        autoHeight
      />
      {selectedDeleteId && (
        <DeleteModal
          title={t('deleteWOItemTitle')}
          message={t('deleteWOItemMessage')}
          open={selectedDeleteId ? true : false}
          handleDelete={deleteWorkOrderItem}
          handleClose={() => {
            setSelectedDeleteId(undefined);
          }}
        />
      )}
      {selectedItem && (
        <WorkOrderItemModal
          id="work-item-modal"
          title={t('workOrderItemModalEditTitle')}
          data={selectedItem}
          open={selectedItem ? true : false}
          handleClose={() => {
            setSelectedItem(undefined);
          }}
          handleSubmit={(formData: WorkOrderItem) =>
            updateWorkOrderItem(formData)
          }
        />
      )}
    </>
  );
};

export default Index;
