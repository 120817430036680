import React, {useEffect, useState} from 'react';
import {
  Input,
  Suspense,
  Card,
  TextArea,
  DeleteModal,
  DeviceModal,
  DeviceDetailsModal,
  Button,
} from '../../components';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useParams} from 'react-router-dom';
import {
  useGetDeviceById,
  useDeleteDeviceDetails,
  useCreateDeviceDetails,
  useUpdateDeviceDetails,
  useUpdateDevice,
} from '../../service/DevicesService/useDeviceService';
import {DeviceDetails} from '../../service/DevicesService/types';
import {Theme, useTheme} from '@mui/material';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {Client} from '../../service/ClientService/types';

const Index: React.FC = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const theme = useTheme();
  const {isAdmin} = useAuthContext();
  const {data, isLoading: isPendingData, getDeviceById} = useGetDeviceById(id);

  const [selectedDeleteId, setSelectedDeleteId] = useState<number | undefined>(
    undefined,
  );
  const [selectedDeviceDetailsData, setSelectedDeviceDetailsData] =
    useState<DeviceDetails>(undefined);
  const [openDeviceDetailsModal, setOpenDeviceDetailsModal] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);

  //actions
  const {deleteDeviceDetails, isSuccessDelete} =
    useDeleteDeviceDetails(selectedDeleteId);
  const {isSuccessCreate, createDeviceDetails} = useCreateDeviceDetails(
    data?.id,
  );
  const {isSuccessUpdate, updateDeviceDetails} = useUpdateDeviceDetails(
    selectedDeviceDetailsData?.id,
  );
  const {isSuccessUpdateDevice, updateDeviceById} = useUpdateDevice(data?.id);

  useEffect(() => {
    if (isSuccessDelete) {
      setSelectedDeleteId(undefined);
      getDeviceById();
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isSuccessCreate) {
      setOpenDeviceDetailsModal(false);
      getDeviceById();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      setOpenDeviceDetailsModal(false);
      setSelectedDeviceDetailsData(undefined);
      getDeviceById();
    }
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (selectedDeviceDetailsData) {
      setOpenDeviceDetailsModal(true);
    }
  }, [selectedDeviceDetailsData]);

  useEffect(() => {
    if (isSuccessUpdateDevice) {
      setOpenDeviceModal(false);
      getDeviceById();
    }
  }, [isSuccessUpdateDevice]);

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid
        item
        sx={{
          alignSelf: 'center',
        }}>
        <Typography variant="h1">{t('deviceTitle')}</Typography>
      </Grid>
      <Grid
        item
        display={'flex'}
        sx={mainGridCss}
        component={Paper}
        elevation={6}>
        <Grid container display={'flex'} direction={'column'}>
          <Suspense isLoading={isPendingData}>
            <Grid container>
              <Grid item>
                <Typography variant="h3" sx={sectionCss}>
                  {t('devicesMainInfo')}
                </Typography>
              </Grid>
              {isAdmin && (
                <Grid item>
                  <IconButton
                    aria-label="edit device"
                    component="button"
                    sx={editButtonCss(theme)}
                    onClick={() => setOpenDeviceModal(true)}>
                    <EditOutlinedIcon sx={editButtonIconCss} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Input
                id="device-name"
                name="name"
                label={t('deviceName')}
                initialValue={data?.name || ''}
                disabled={true}
              />
              <Input
                id="device-client"
                name="client"
                label={t('deviceClient')}
                initialValue={(data?.client as Client)?.name || ''}
                disabled={true}
              />
              <TextArea
                id="device-description"
                name="description"
                label={t('deviceDescription')}
                initialValue={data?.description || ''}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <Grid container display={'flex'} direction={'column'}>
                <Grid container sx={otherInfoTextGridCss}>
                  <Grid item>
                    <Typography variant="h3" sx={sectionCss}>
                      {t('devicesDetailInfo')}
                    </Typography>
                  </Grid>
                  {isAdmin && (
                    <Grid item alignSelf={'flex-end'} sx={buttonGridCss}>
                      <Button
                        id="add-device"
                        startIcon={<AddIcon />}
                        text={t('addDeviceDetailsBtn')}
                        onClick={() => setOpenDeviceDetailsModal(true)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item sx={detailsGridCss}>
                  {data?.deviceDetails?.map(
                    (detail: DeviceDetails, i: number) => (
                      <Card
                        id={detail?.name}
                        key={`${i}-${detail?.name}`}
                        title={detail?.name}
                        isCloseButtonVisible={isAdmin}
                        isButtonVisible={isAdmin}
                        buttonText={t('updateBtn')}
                        onButtonClick={() =>
                          setSelectedDeviceDetailsData(detail)
                        }
                        handleClose={() => setSelectedDeleteId(detail.id)}
                        sx={cardCss(theme)}>
                        <Typography sx={detailValueCss}>
                          {detail?.value}
                        </Typography>
                        <Typography>{detail?.description}</Typography>
                      </Card>
                    ),
                  )}
                </Grid>
              </Grid>
            </Grid>
            {selectedDeleteId && isAdmin && (
              <DeleteModal
                title={t('deleteDeviceDetailsTitle')}
                message={t('deleteDeviceDetailsMessage')}
                open={true}
                handleDelete={deleteDeviceDetails}
                handleClose={() => {
                  setSelectedDeleteId(undefined);
                }}
              />
            )}
            {openDeviceDetailsModal && isAdmin && (
              <DeviceDetailsModal
                data={selectedDeviceDetailsData}
                title={
                  !selectedDeviceDetailsData
                    ? t('deviceDetailsAddModalTitle')
                    : t('deviceDetailsEditModalTitle')
                }
                open={openDeviceDetailsModal}
                handleClose={() => {
                  setOpenDeviceDetailsModal(false);
                  setSelectedDeviceDetailsData(undefined);
                }}
                handleSubmit={
                  !selectedDeviceDetailsData
                    ? createDeviceDetails
                    : updateDeviceDetails
                }
              />
            )}
            {openDeviceModal && isAdmin && (
              <DeviceModal
                id="device-edit-modal"
                data={data}
                title={t('deviceModalEditTitle')}
                open={openDeviceModal}
                handleClose={() => {
                  setOpenDeviceModal(false);
                }}
                handleSubmit={updateDeviceById}
              />
            )}
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};

const editButtonCss = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  marginLeft: '10px',
  marginTop: '5px',
  width: 50,
  height: 50,
  borderRadius: '10px',
});

const editButtonIconCss = {
  color: '#000',
  width: 40,
  height: 40,
};

const mainGridCss = {
  flexGrow: 1,
  backgroundColor: '#fff',
  width: {xs: '95vw', md: '70vw'},
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '30px',
  padding: {xs: '1em', md: '3em'},
  alignSelf: 'center',
};

const sectionCss = {
  color: '#000',
  paddingTop: '20px',
  paddingBottom: '10px',
};

const detailsGridCss = {
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(2, 1fr)',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(3, 1fr)',
  },
  gridGap: '10px',
  paddingTop: '20px',
};

const detailValueCss = {
  fontSize: '1.2em',
  fontWeight: 'bold',
  paddingBottom: '5px',
};

const otherInfoTextGridCss = {
  display: 'flex',
  direction: 'row',
  width: '100%',
  justifyContent: 'space-between',
};

const cardCss = (theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.5em',
  },
});
const buttonGridCss = {paddingRight: '10px'};

export default Index;
