export const isValidEmailFormat = (value: string) => {
  if (value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i)) {
    return true;
  }
  return false;
};

export const isValidLength = (
  value: string,
  minLenght?: number,
  maxLenght?: number,
) => {
  const trimedValue = value.trim();
  if (minLenght && trimedValue.length < minLenght) {
    return false;
  } else if (maxLenght && trimedValue.length > maxLenght) {
    return false;
  }
  return true;
};

export const isStrongEnough = (value: string) => {
  if (
    value.match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,}$/,
    )
  ) {
    return true;
  }
  return false;
};

export const isPhoneLengthValid = (phone: string) => {
  {
    const withoutSpaces = phone.split(' ').join(''); // trim not working
    return (
      withoutSpaces.length === 0 ||
      (withoutSpaces.length > 8 && withoutSpaces.length <= 15)
    );
  }
};
