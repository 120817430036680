export type ErrorResponse = {
  path?: string;
  error?: string;
  code?: string;
  message?: string;
  status?: number;
  title?: string;
  statusCode?: number;
};

export type AccessToken = {
  sub: string;
  iat: number;
  exp: number;
  id: string;
  roles: string;
};

export type SuccessResponse = {
  title: string;
  messages: string;
};

export type Sort = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export type PaginationInfo = {
  sort: Sort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  unpaged: boolean;
  paged: boolean;
};

export type PaginationModel = {
  page: number;
  pageSize: number;
};

export type SortModel = {
  field?: string;
  sort?: string;
};

export type FilterOptions = {
  field: string;
  fromInput: string;
  id: number;
  operator: string;
  value: string;
};
export type FilterModel = {
  items?: FilterOptions[];
  logicOperator?: string;
  quickFilterLogicOperator?: string;
  quickFilterValues?: FilterOptions[];
};

export type TableData = {
  content?: any;
  pageable?: PaginationInfo;
  last?: boolean;
  totalElements?: number;
  totalPages?: number;
  size?: number;
  number?: number;
  sort?: Sort;
  first?: boolean;
  numberOfElements?: number;
  empty?: boolean;
};

export enum Status {
  IDLE = 'idle',
  ERROR = 'error',
  SUCCESS = 'success',
  PENDING = 'pending',
}

export type SearchCriteria = {
  key?: string;
  operation?: string;
  value?: string;
};
