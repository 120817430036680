import React, {useEffect} from 'react';
import Box from '@mui/system/Box';
import {Input, Dialog, InputType} from '../..';
import useForm from '../../../hooks/useForm/useForm';
import {useTranslation} from 'react-i18next';
import {WorkOrderItem} from '../../../service/WorkOrderItemsService/types';
import {Select} from '../..';
import {SelectItem} from '../../common/Select/types';
import {isValidLength} from '../../../validation/appValidations';

interface Props {
  id: string;
  data?: WorkOrderItem;
  title: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (formData: WorkOrderItem) => void;
}

const Index: React.FC<Props> = ({
  id,
  data,
  title,
  open,
  handleSubmit,
  handleClose,
}) => {
  const {t} = useTranslation();

  const initData = {
    code: '',
    name: '',
    unit: '',
    quantity: '',
    workOrderItemType: {label: '', value: ''},
  };

  const isRequired = {required: {value: true}};

  const validations = {
    name: isRequired,
    code: {
      custom: [
        {
          isValid: (value: string) => isValidLength(value, null, 10),
          message: t('fieldLength'),
        },
      ],
    },
    unit: {
      custom: [
        {
          isValid: (value: string) => isValidLength(value, null, 10),
          message: t('fieldLength'),
        },
      ],
    },
    workOrderItemType: isRequired,
  };

  const WOTypes = [
    {value: 'PRODUCT', label: t('PRODUCT', {ns: 'api'})},
    {value: 'SERVICE', label: t('SERVICE', {ns: 'api'})},
  ];

  const onSubmit = (data: WorkOrderItem) => {
    const parseData = {
      ...data,
      workOrderItemType: (data?.workOrderItemType as SelectItem)?.value,
    };
    handleSubmit(parseData);
  };

  const {
    formData,
    handleInputChange,
    handleSelectChange,
    formErrors,
    isValidForm,
    setFormData,
  } = useForm<WorkOrderItem>(initData, validations);

  useEffect(() => {
    if (data) {
      const selected = WOTypes.find(v => v.value == data.workOrderItemType);
      setFormData({
        ...data,
        workOrderItemType: selected,
      });
    }
  }, [data]);

  return (
    <Dialog
      id={id}
      open={open}
      title={title}
      fullWidth={true}
      secondButtonDisabled={!isValidForm}
      handleClose={handleClose}
      secondButtonTitle={!data ? t('createBtn') : t('updateBtn')}
      handleSecondButtonClick={() => onSubmit(formData)}>
      <Box>
        <Input
          id="work-order-item-code"
          name="code"
          label={t('workOrderItemModalCode')}
          initialValue={formData.code}
          onChange={handleInputChange}
          errorText={formErrors.code || ''}
        />
        <Input
          id="work-order-item-name"
          name="name"
          label={t('workOrderItemModalName')}
          initialValue={formData.name}
          onChange={handleInputChange}
          errorText={formErrors?.name || ''}
        />
        <Input
          id="work-order-item-unit"
          name="unit"
          label={t('workOrderItemModalUnit')}
          initialValue={formData.unit}
          onChange={handleInputChange}
          errorText={formErrors.unit || ''}
        />
        <Input
          id="work-order-item-quantity"
          name="quantity"
          type={InputType.NUMBER}
          label={t('workOrderItemModalQuantity')}
          initialValue={formData.quantity.toString()}
          onChange={handleInputChange}
          errorText={formErrors.quantity || ''}
        />
        <Select
          id="work-order-item-type"
          name="workOrderItemType"
          items={WOTypes}
          label={t('workOrderItemModalType')}
          value={formData?.workOrderItemType as SelectItem}
          onChange={handleSelectChange}
          errorText={formErrors.workOrderItemType || ''}
        />
      </Box>
    </Dialog>
  );
};

export default Index;
