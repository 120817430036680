import React from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';
import dayjs from 'dayjs';

interface Props {
  id: string;
  name: string;
  compareDate?: string;
  compareDate2?: string;
  initialValue: string | null;
  label?: string;
  disabled?: boolean;
  errorText?: string;
  onChange?: (
    name: string,
    value: string | null,
    compareDate: string | null,
    compareDate2: string | null,
  ) => void;
}

export const Index: React.FC<Props> = ({
  id,
  name,
  compareDate,
  compareDate2,
  initialValue,
  label,
  disabled,
  errorText,
  onChange,
}) => {
  const theme = useTheme();

  const handleChange = (value: dayjs.Dayjs) => {
    let formattedDate: string | null;
    if (
      value?.format('YYYY-MM-DD HH:mm:ss') === 'Invalid Date' ||
      !value?.format('YYYY-MM-DD HH:mm:ss')
    ) {
      formattedDate = null;
    } else {
      formattedDate = value?.format('YYYY-MM-DD HH:mm:ss');
    }
    if (onChange) {
      onChange(name, formattedDate, compareDate, compareDate2);
    }
  };

  return (
    <Box py={2} px={2} sx={{width: '100%'}} id={id}>
      <Typography sx={typographyCss}>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={disabled}
          sx={inputCss}
          value={initialValue && dayjs(initialValue)}
          onChange={handleChange}
        />
      </LocalizationProvider>
      {errorText && (
        <Typography sx={[errorTextCss, {color: theme.palette.error.light}]}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

const inputCss = {
  '.MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
  display: 'flex',
  flexGrow: 1,
};

const typographyCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  color: 'gray',
  paddingLeft: '5px',
};

const errorTextCss = {
  padding: '7px',
};

export default Index;
