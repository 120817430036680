import React, {useEffect, useRef} from 'react';
import {Input, InputType, Button} from '../../components';
import {Grid, Box, Typography, useTheme} from '@mui/material';
import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useForm from '../../hooks/useForm/useForm';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';

const Index: React.FC = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const usernameInput = useRef<HTMLInputElement>();
  const {login, isCheckingCredetials, contextError, user} = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/', {replace: true});
    }
  });

  const initData = {
    username: '',
    password: '',
  };

  const isRequired = {required: {value: true}};

  const validations = {
    username: isRequired,
    password: isRequired,
  };

  const {formData, handleInputChange, formErrors, isValidForm} = useForm(
    initData,
    validations,
  );

  useEffect(() => {
    usernameInput?.current?.focus();
  }, []);

  return (
    <Grid component="main" sx={mainGridCss}>
      <Grid
        item
        sx={[
          imageGridCss,
          {
            backgroundColor: theme.palette.primary.main,
            width: '100%',
          },
        ]}>
        <div>
          <img
            srcSet="/zemlja-tw-machine.png"
            src="/zemlja-tw-machine.png"
            alt="TWM image"
            loading="lazy"
            style={{alignSelf: 'center', width: '50vw', objectFit: 'contain'}}
          />
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        component={Paper}
        elevation={6}
        square
        sx={formGridCss}>
        <Box my={20} mx={5} sx={formContainerCss}>
          <Typography sx={[signinTextCss, {color: theme.palette.primary.main}]}>
            {t('signinText')}
          </Typography>
          {contextError && (
            <Typography sx={[errorTextCss, {color: theme.palette.error.light}]}>
              {contextError}
            </Typography>
          )}
          <Box sx={formCss}>
            <Input
              ref={usernameInput}
              id="input-username"
              name="username"
              label={t('username')}
              initialValue={formData.username}
              onChange={handleInputChange}
              errorText={formErrors?.username || ''}
            />
            <Input
              id="input-password"
              name="password"
              label={t('password')}
              initialValue={formData.password}
              onChange={handleInputChange}
              type={InputType.PASSWORD}
              errorText={formErrors?.password || ''}
            />
            <Box sx={centerCss}>
              <Button
                id="login-button"
                text={t('signin')}
                sx={buttonCss}
                endIcon={<ArrowForwardIcon />}
                disabled={!isValidForm || isCheckingCredetials}
                onClick={() => login(formData)}
              />
            </Box>
            <Box sx={[centerCss, linkCss]}>
              <Link href="/registration" underline="none">
                {t('regAccount')}
              </Link>
            </Box>
            <Box sx={[centerCss, linkCss]}>
              <Link href="/password-reset" underline="none">
                {t('forgotPasswordLink')}
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
const imageGridCss = {
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomLeftRadius: 20,
  borderTopLeftRadius: 20,
  width: {sm: 0, md: '50vw'},
  display: {xs: 'none', sm: 'none', md: 'flex'},
};

const signinTextCss = {
  fontSize: '2em',
  display: 'flex',
  justifyContent: 'center',
};

const mainGridCss = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  height: '100vh',
  width: '100vw',
  padding: {sx: '10px', sm: '10px', md: '20px'},
};

const formGridCss = {
  alignItems: 'stretch',
  width: {sm: '100vw', md: '50vw'},
  borderTopLeftRadius: {sm: '20px', md: '0'},
  borderBottomLeftRadius: {sm: '20px', md: '0'},
  borderBottomRightRadius: 20,
  borderTopRightRadius: 20,
};

const formContainerCss = {
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
};

const formCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
};

const errorTextCss = {
  padding: '7px',
  alignSelf: 'center',
};

const centerCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const buttonCss = {
  paddingLeft: '50px',
  paddingRight: '50px',
};

const linkCss = {
  paddingTop: '20px',
};
export default Index;
