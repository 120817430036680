import React from 'react';
import {CircularLoader} from '../..';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

const Index: React.FC<Props> = ({isLoading, children}) => {
  return isLoading ? <CircularLoader /> : <>{children}</>;
};
export default Index;
