import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {Button} from '../..';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {SxProps} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';

type SXProps = {
  main?: SxProps;
  title?: SxProps;
  button?: SxProps;
};

interface Props {
  id: string;
  title?: string;
  children: React.ReactNode;
  isButtonVisible?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  buttonText?: string;
  isCloseButtonVisible?: boolean;
  onButtonClick?: () => void;
  handleClose?: () => void;
  sx?: SXProps;
}

const Index: React.FC<Props> = ({
  id,
  title,
  sx,
  children,
  isButtonVisible,
  isCloseButtonVisible,
  buttonText,
  startIcon,
  endIcon,
  onButtonClick,
  handleClose,
}) => {
  const theme = useTheme();

  const closeButtonCss = {
    color: theme.palette.grey[500],
  };

  return (
    <Card
      id={id}
      sx={{minWidth: 200, borderRadius: '15px', ...sx?.main}}
      component={Paper}
      elevation={6}>
      {isCloseButtonVisible && (
        <Grid container display={'row'} justifyContent={'end'}>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={closeButtonCss}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <CardContent>
        {title && (
          <Typography
            sx={{fontSize: 14, ...sx?.title}}
            color="text.secondary"
            gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
      <CardActions>
        {isButtonVisible && (
          <Button
            id={`${id}-button`}
            text={buttonText}
            endIcon={endIcon}
            startIcon={startIcon}
            onClick={onButtonClick}
            sx={sx?.button}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default Index;
