import React, {useState, useEffect} from 'react';
import {
  Input,
  Suspense,
  Card,
  DeleteModal,
  ClientDetailsModal,
  ClientModal,
  Button,
  ClientDevicesTable,
  DeviceModal,
} from '../../components';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useParams} from 'react-router-dom';
import {ClientDetails} from '../../service/ClientService/types';
import {useTheme} from '@mui/material';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import {
  useCreateClientDetails,
  useUpdateClientDetails,
  useDeleteClientDetails,
  useUpdateClient,
  useGetClientById,
} from '../../service/ClientService/useClientService';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {Theme} from '@mui/material';
import {useCreateDevice} from '../../service/DevicesService/useDeviceService';
import {Status} from '../../service/types';

const Index: React.FC = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const theme = useTheme();
  const {isAdmin} = useAuthContext();
  const {data, isLoading: isPendingData, getClientById} = useGetClientById(id);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | undefined>(
    undefined,
  );
  const [selectedClientDetailsData, setSelectedClientDetailsData] =
    useState<ClientDetails>(undefined);
  const [openClientDetailsModal, setOpenClientDetailsModal] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);

  //actions
  const {deleteClientDetails, isSuccessDelete} =
    useDeleteClientDetails(selectedDeleteId);
  const {isSuccessCreate, createClientDetails} = useCreateClientDetails(
    data?.id,
  );
  const {isSuccessUpdate, updateClientDetails} = useUpdateClientDetails(
    selectedClientDetailsData?.id,
  );
  const {isSuccessUpdateClient, updateClientById} = useUpdateClient(data?.id);
  const {createDevice, createStatus: isDeviceCreated} = useCreateDevice();

  useEffect(() => {
    if (isSuccessDelete) {
      setSelectedDeleteId(undefined);
      getClientById();
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isSuccessCreate) {
      setOpenClientDetailsModal(false);
      getClientById();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (isDeviceCreated === Status.SUCCESS) {
      setOpenDeviceModal(false);
      getClientById();
    }
  }, [isDeviceCreated]);

  useEffect(() => {
    if (isSuccessUpdate) {
      setOpenClientDetailsModal(false);
      setSelectedClientDetailsData(undefined);
      getClientById();
    }
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (selectedClientDetailsData) {
      setOpenClientDetailsModal(true);
    }
  }, [selectedClientDetailsData]);

  useEffect(() => {
    if (isSuccessUpdateClient) {
      setOpenClientModal(false);
      getClientById();
    }
  }, [isSuccessUpdateClient]);

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid
        item
        sx={{
          alignSelf: 'center',
        }}>
        <Typography variant="h1">{t('clientProfileTitle')}</Typography>
      </Grid>
      <Grid
        item
        display={'flex'}
        sx={mainGridCss}
        component={Paper}
        elevation={6}>
        <Grid container display={'flex'} direction={'column'}>
          <Suspense isLoading={isPendingData}>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h3" sx={sectionCss}>
                    {t('clientProfileMainInfo')}
                  </Typography>
                </Grid>
                {isAdmin && (
                  <Grid item>
                    <IconButton
                      aria-label="edit client"
                      component="button"
                      sx={editButtonCss(theme)}
                      onClick={() => setOpenClientModal(true)}>
                      <EditOutlinedIcon sx={editButtonIconCss} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Input
                id="client-name"
                name="name"
                label={t('clientProfileName')}
                initialValue={data?.name || ''}
                disabled={true}
              />
              <Input
                id="client-first-name"
                name="firstName"
                label={t('clientProfileFirstName')}
                initialValue={data?.firstName || ''}
                disabled={true}
              />
              <Input
                id="client-last-name"
                name="lastName"
                label={t('clientProfileLastName')}
                initialValue={data?.lastName || ''}
                disabled={true}
              />
              <Input
                id="client-email"
                name="email"
                label={'E-mail'}
                initialValue={data?.email || ''}
                disabled={true}
              />
              <Input
                id="client-phone"
                name="phone"
                label={t('clientProfilePhone')}
                initialValue={data?.phone || ''}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <Grid container display={'flex'} direction={'column'}>
                <Grid container sx={otherInfoTextGridCss}>
                  <Grid item>
                    <Typography variant="h3" sx={sectionCss}>
                      {t('clientProfileDevices')}
                    </Typography>
                  </Grid>
                  {isAdmin && (
                    <Grid item alignSelf={'flex-end'} sx={buttonGridCss}>
                      <Button
                        id="add-client-device"
                        startIcon={<AddIcon />}
                        text={t('addDeviceBtn')}
                        onClick={() => setOpenDeviceModal(true)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item sx={machineryCss}>
                  {data?.devices && data?.devices.length > 0 ? (
                    <ClientDevicesTable
                      data={data?.devices}
                      getClient={getClientById}
                    />
                  ) : (
                    <Typography>{t('clientProfileNoMachinery')}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container display={'flex'} direction={'column'}>
                <Grid container sx={otherInfoTextGridCss}>
                  <Grid item>
                    <Typography variant="h3" sx={sectionCss}>
                      {t('clientProfileDetailInfo')}
                    </Typography>
                  </Grid>
                  {isAdmin && (
                    <Grid item alignSelf={'flex-end'} sx={buttonGridCss}>
                      <Button
                        id="add-client"
                        startIcon={<AddIcon />}
                        text={t('addClientDetailsBtn')}
                        onClick={() => setOpenClientDetailsModal(true)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item sx={detailsGridCss}>
                  {data?.clientDetailsList?.map(
                    (detail: ClientDetails, i: number) => (
                      <Card
                        id={detail?.name}
                        key={`${i}-${detail?.name}`}
                        title={detail?.name}
                        isCloseButtonVisible={isAdmin}
                        isButtonVisible={isAdmin}
                        buttonText={t('updateBtn')}
                        onButtonClick={() =>
                          setSelectedClientDetailsData(detail)
                        }
                        handleClose={() => setSelectedDeleteId(detail.id)}
                        sx={cardCss(theme)}>
                        <Typography sx={detailValueCss}>
                          {detail?.value}
                        </Typography>
                        <Typography>{detail?.description}</Typography>
                      </Card>
                    ),
                  )}
                </Grid>
              </Grid>
            </Grid>
            {selectedDeleteId && isAdmin && (
              <DeleteModal
                title={t('deleteDetailsTitle')}
                message={t('deleteDetailsMessage')}
                open={true}
                handleDelete={deleteClientDetails}
                handleClose={() => {
                  setSelectedDeleteId(undefined);
                }}
              />
            )}
            {openClientDetailsModal && isAdmin && (
              <ClientDetailsModal
                data={selectedClientDetailsData}
                title={
                  !selectedClientDetailsData
                    ? t('clientDetailsAddModalTitle')
                    : t('clientDetailsEditModalTitle')
                }
                open={openClientDetailsModal}
                handleClose={() => {
                  setOpenClientDetailsModal(false);
                  setSelectedClientDetailsData(undefined);
                }}
                handleSubmit={
                  !selectedClientDetailsData
                    ? createClientDetails
                    : updateClientDetails
                }
              />
            )}
            {openClientModal && isAdmin && (
              <ClientModal
                id="client-edit-modal"
                data={data}
                title={t('clientModalEditTitle')}
                open={openClientModal}
                handleClose={() => {
                  setOpenClientModal(false);
                }}
                handleSubmit={updateClientById}
              />
            )}
            {openDeviceModal && isAdmin && (
              <DeviceModal
                id="client-device-add-modal"
                title={t('deviceModalAddTitle')}
                open={openDeviceModal}
                client={{value: data?.id.toString(), label: data?.name}}
                handleClose={() => {
                  setOpenDeviceModal(false);
                }}
                handleSubmit={createDevice}
              />
            )}
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};
const editButtonCss = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  marginLeft: '10px',
  marginTop: '5px',
  width: 50,
  height: 50,
  borderRadius: '10px',
});

const editButtonIconCss = {
  color: '#000',
  width: 40,
  height: 40,
};

const sectionCss = {
  color: '#000',
  paddingTop: '20px',
  paddingBottom: '10px',
};

const mainGridCss = {
  flexGrow: 1,
  backgroundColor: '#fff',
  width: {xs: '95vw', md: '70vw'},
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '30px',
  padding: {xs: '1em', md: '3em'},
  alignSelf: 'center',
};

const detailsGridCss = {
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(2, 1fr)',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(3, 1fr)',
  },
  gridGap: '10px',
  paddingTop: '20px',
};

const detailValueCss = {
  fontSize: '1.2em',
  fontWeight: 'bold',
  paddingBottom: '5px',
};

const otherInfoTextGridCss = {
  display: 'flex',
  direction: 'row',
  width: '100%',
  justifyContent: 'space-between',
};

const cardCss = (theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.5em',
  },
});

const machineryCss = {
  marginBottom: '20px',
};

const buttonGridCss = {paddingRight: '10px'};

export default Index;
