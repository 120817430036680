import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationCroatian from './translation/Croatian/AppTranslation/translation.json';
import translationEnglish from './translation/English/AppTranslation/translation.json';
import apiTranslationCoratian from './translation/Croatian/ApiTranslation/translation.json';
import apiTranslationEnglish from './translation/English/ApiTranslation/translation.json';

const resources = {
  cro: {app: translationCroatian, api: apiTranslationCoratian},
  en: {app: translationEnglish, api: apiTranslationEnglish},
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['app', 'api'],
    defaultNS: 'app',
    fallbackNS: 'app',
    fallbackLng: 'cro',
    resources,
  });

export default i18next;
