import {httpGet} from '../common';
import {useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export const useGetAllWOCount = () => {
  const {t} = useTranslation('api');
  const {
    refetch: getAllWOCount,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['allWOCount'],
    queryFn: async () => {
      return await httpGet<number>('work-order/all/count', t);
    },
    retry: 0,
    enabled: false,
  });

  return {
    getAllWOCount,
    data,
    isLoading,
  };
};

export const useGetAllOpenedWOCount = () => {
  const {t} = useTranslation('api');
  const {
    refetch: getAllOpenedWOCount,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['allOpenedWOCount'],
    queryFn: async () => {
      return await httpGet<number>('work-order/open/count', t);
    },
    retry: 0,
    enabled: false,
  });

  return {
    getAllOpenedWOCount,
    data,
    isLoading,
  };
};

export const useGetAllOpenedWOCountUser = (id: number) => {
  const {t} = useTranslation('api');
  const {
    refetch: getAllOpenedWOCountUser,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['allOpenedWOCountUser'],
    queryFn: async () => {
      return await httpGet<number>(`work-order/open-count/${id}`, t);
    },
    retry: 0,
    enabled: false,
  });

  return {
    getAllOpenedWOCountUser,
    data,
    isLoading,
  };
};

export const useGetAllClosedWOCountUser = (id: number) => {
  const {t} = useTranslation('api');
  const {
    refetch: getAllClosedWOCountUser,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['allClosedWOCountUser'],
    queryFn: async () => {
      return await httpGet<number>(`work-order/closed-count/${id}`, t);
    },
    retry: 0,
    enabled: false,
  });

  return {
    getAllClosedWOCountUser,
    data,
    isLoading,
  };
};
