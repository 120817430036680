import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {useAuthContext} from '../../../contexts/useAuthContext/useAuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {Device} from '../../../service/DevicesService/types';
import {DeleteModal, DeviceModal} from '../..';
import {useDeleteDeviceById} from '../../../service/DevicesService/useDeviceService';
import {Status} from '../../../service/types';
import {RefetchOptions, QueryObserverResult} from '@tanstack/react-query';
import {Client} from '../../../service/ClientService/types';
import {useUpdateDevice} from '../../../service/DevicesService/useDeviceService';
import {useGetDeviceById} from '../../../service/DevicesService/useDeviceService';

const BootstrapDataGrid = styled(DataGrid)({
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
});

interface Props {
  data: Device[];
  getClient: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<Client, Error>>;
}

const Index: React.FC<Props> = ({data, getClient}) => {
  const {t} = useTranslation();
  const {isAdmin} = useAuthContext();

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined,
  );
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | undefined>(
    undefined,
  );

  const {deleteDeviceById, deleteStatus} =
    useDeleteDeviceById(selectedDeleteId);

  const {data: device, getDeviceById} = useGetDeviceById(selectedItemId, false);

  const {isSuccessUpdateDevice, updateDeviceById} = useUpdateDevice(device?.id);

  useEffect(() => {
    if (deleteStatus === Status.SUCCESS) {
      setSelectedDeleteId(undefined);
      getClient();
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (isSuccessUpdateDevice) {
      setSelectedItemId(undefined);
      getClient();
    }
  }, [isSuccessUpdateDevice]);

  let columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('devicesTableName'),
      flex: 1,
      align: 'left',
      headerAlign: 'center',
      minWidth: 500,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const handleEdit = (params: GridRenderCellParams) => {
    setSelectedItemId(params?.row.id);
  };

  useEffect(() => {
    if (selectedItemId) {
      getDeviceById();
    }
  }, [selectedItemId]);

  if (isAdmin) {
    columns = [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        minWidth: 100,
        maxWidth: 120,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => handleEdit(params)}>
              <EditIcon color={'primary'} />
            </IconButton>
            <IconButton onClick={() => setSelectedDeleteId(params?.row?.id)}>
              <DeleteIcon color={'primary'} />
            </IconButton>
          </>
        ),
      },
    ];
  }
  return (
    <>
      <BootstrapDataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize: 5},
          },
        }}
        pageSizeOptions={[5]}
        rowSelection={false}
        autoHeight
      />
      {selectedDeleteId && isAdmin && (
        <DeleteModal
          title={t('deleteDeviceTitle')}
          message={t('deleteDeviceMessage')}
          open={selectedDeleteId ? true : false}
          handleDelete={deleteDeviceById}
          handleClose={() => {
            setSelectedDeleteId(undefined);
          }}
        />
      )}
      {device && isAdmin && (
        <DeviceModal
          id="client-device-edit-modal"
          data={device}
          title={t('deviceModalEditTitle')}
          open={selectedItemId ? true : false}
          handleClose={() => {
            setSelectedItemId(undefined);
          }}
          handleSubmit={updateDeviceById}
        />
      )}
    </>
  );
};

export default Index;
